import React from 'react'
import { Box } from 'rebass'
import { useSelector } from 'react-redux'
import Fund from './fund'
import Transfer from './transfer'
import Withdraw from './withdraw'
import Profile from './profile'
import History from './history'
import Faucet from './faucet'
import Community from './community'

export default function Home() {
  const { selectedMenu } = useSelector(state => state)
  let selectedComponent
  if (selectedMenu === 'fund') {
    selectedComponent = <Fund />
  } else if (selectedMenu === 'transfer') {
    selectedComponent = <Transfer />
  } else if (selectedMenu === 'withdraw') {
    selectedComponent = <Withdraw />
  } else if (selectedMenu === 'history') {
    selectedComponent = <History />
  } else if (selectedMenu === 'profile') {
    selectedComponent = <Profile />
  } else if (selectedMenu === 'faucet') {
    selectedComponent = <Faucet />
  } else if (selectedMenu === 'community') {
    selectedComponent = <Community />
  }
  return (
    <Box
      sx={{
        margin: '3vh auto auto auto',
        background: '#fff',
        padding: 0,
        maxWidth:
          selectedMenu === 'history' ||
          selectedMenu === 'community' ||
          selectedMenu === 'profile'
            ? '100%'
            : '24em',
        width:
          selectedMenu === 'history' ||
          selectedMenu === 'community' ||
          selectedMenu === 'profile'
            ? '100%'
            : 'auto'
      }}
    >
      {selectedComponent}
    </Box>
  )
}
