import { useEffect } from 'react'
import { Flex, Box, Text } from 'rebass'
import { Input } from '@rebass/forms'
import { useDispatch, useSelector } from 'react-redux'
import { formatEther } from 'ethers'
import { BigNumber } from '@ethersproject/bignumber'
import Button from './button'
import { drip, setFaucetRecipient } from './redux'
import { NATIVE_ADDRESS_PATTERN, FAUCET_DRIP } from './constants'

export default function Faucet() {
  const dispatch = useDispatch()
  let { faucetRecipient } = useSelector(state => state)
  const valid = NATIVE_ADDRESS_PATTERN.test(faucetRecipient?.toLowerCase())
  const amount = BigNumber.from(FAUCET_DRIP.toString())
  useEffect(() => {
    dispatch(setFaucetRecipient())
    return async () => {}
  }, [])
  return (
    <Box
      sx={{
        maxWidth: '100%',
        border: '0.1875em solid #000',
        boxShadow: '0 0.625em',
        padding: '0.625em'
      }}
    >
      <Flex
        sx={{
          fontSize: [24, 26, 28],
          fontWeight: 'bold',
          marginTop: 0,
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}
      >
        <span>Faucet</span>
      </Flex>
      <Box sx={{ margin: '0.625em 0 0.625em 0', fontSize: [16, 18, 20] }}>
        <Input
          type="text"
          id="faucet-input"
          placeholder="Recipient"
          onChange={e => dispatch(setFaucetRecipient(e.target.value))}
          value={faucetRecipient ?? ''}
          bg="#fff"
          sx={{
            textAlign: 'center',
            // fontSize: [16, 18, 20],
            marginBottom: '0.3125em'
          }}
        />
        <Button
          disabled={!valid}
          aria-label="Mint"
          style={{
            marginTop: '0.3125em',
            cursor: valid ? 'pointer' : 'not-allowed'
          }}
          onClick={() => dispatch(drip())}
        >
          Mint {formatEther(amount.toString())} CBAY
        </Button>
      </Box>
    </Box>
  )
}
