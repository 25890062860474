import React from 'react'
import Button from './button'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { connect, disconnect, useMyMetaMask } from './redux'

export default function ConnectButton({ style }) {
  const { account } = useMyMetaMask()
  const { shieldedAddress } = useSelector(state => state)
  const dispatch = useDispatch()
  const isSmol = useMediaQuery({ query: '(max-width: 21.875em)' })

  return (
    <Button
      aria-label="Connect wallet"
      sx={{
        width: 'auto',
        ...style
      }}
      onClick={_ =>
        shieldedAddress ? dispatch(disconnect()) : dispatch(connect(account))
      }
    >
      {isSmol ? '🔌' : shieldedAddress ? 'Disconnect' : 'Connect'}
    </Button>
  )
}
