const { BigNumber } = require('@ethersproject/bignumber')
const { bigNumToHex, queryFilterBatched, sortDescByAmount } = require('./utils')

module.exports = (config, poseidonHash) => {
  const Utxo = require('./utxo')(poseidonHash)

  /**
   * Lists unspent transaction outputs by given key pair.
   *
   * @param {string|Contract} pool pool contract
   * @param {KeyPair} keypair spender's shielded account keypair
   * @param {string[]} peers Peer shielded addresses
   * @param {string} tokens ERC20 token addresses
   * @param {boolean} excludeSpent Exclude spent transactions
   * @param {boolean} excludeOthers Exclude others UTXOs
   * @param {number} from block height offset to start search from
   * @returns {{[key:string]: Utxo[]}} Unspent tx outputs
   */
  async function findUtxos({
    pool = config.pool,
    keypair,
    peers,
    tokens,
    excludeSpent = true,
    excludeOthers = true,
    from = config.startBlock
  }) {
    const loadedPeers = config.peers?.map(p => p.shieldedAddress || p) ?? []
    peers = peers ? peers.concat(loadedPeers) : loadedPeers
    const filter = pool.filters.NewCommitment()
    const events = await queryFilterBatched(
      from,
      await config.provider.getBlock().then(b => b.number),
      pool,
      filter
    )
    const utxos = await Promise.all(
      events.map(async event => {
        let utxo
        try {
          const result = Utxo.decrypt(
            keypair,
            peers,
            event.args.encryptedOutput,
            Number(event.args.index)
          )
          utxo = result.utxo
        } catch (_) {} // eslint-disable-line no-empty
        if (!utxo) {
          return null
        } else {
          if (utxo.amount.toString() === '0') {
            return null
          }
          const utxoToken = '0x' + utxo.token.toString('hex').toLowerCase()
          if (!tokens.some(token => utxoToken === token.toLowerCase())) {
            return null
          }
          if (excludeOthers && !utxo.keypair.privkey) {
            return null
          }
          if (excludeSpent) {
            const nullifier = bigNumToHex(utxo.getNullifier(), 32)
            const isSpent = await pool.isSpent(nullifier)
            if (isSpent) {
              return null
            }
          }
          return [utxoToken, utxo]
        }
      })
    )
      .then((sparse) /*[null,[utxoToken, utxo],...]*/ => sparse.filter(Boolean))
      .then((flat) /*[[utxoToken, utxo],...]*/ => {
        return flat.reduce((acc, [utxoToken, utxo]) => {
          if (Array.isArray(acc[utxoToken])) {
            acc[utxoToken].push(utxo)
          } else {
            acc[utxoToken] = [utxo]
          }
          return acc
        }, {})
      })

    return utxos
  }

  /**
   * Select minimal required number of utxos to reach given target amount and token
   *
   * @param {string|Contract} pool pool contract
   * @param {KeyPair} keypair spender's shielded account keypair
   * @param {string[]} peers Peer shielded addresses
   * @param {boolean} excludeSpent Exclude spent transactions
   * @param {boolean} excludeOthers Exclude others UTXOs
   * @param {number} from block height offset to start search from
   * @param {string} token ERC20 token address
   * @param {BigNumber} amount Target value to accumulate
   * @returns {{[key:string]: Utxo[]}} Unspent tx outputs
   */
  async function findUtxosUpTo({
    pool = config.pool,
    keypair,
    peers = [],
    excludeSpent = true,
    excludeOthers = true,
    from = config.startBlock,
    token,
    amount
  }) {
    //VERY IMPORTANT toLowerCase()
    token = token.toLowerCase()
    // console.log("peers", peers)
    const utxos = await findUtxos({
      pool,
      keypair,
      peers,
      excludeSpent,
      excludeOthers,
      from,
      tokens: [token]
    }).then(utxos => sortDescByAmount(utxos[token]).slice(0, 16))
    console.log('>>>>>>>>>>>')
    console.log('>>>>>>>>>>> findUtxos utxos', utxos)
    console.log('>>>>>>>>>>>')
    const _amount = BigNumber.from(amount)
    let accu = BigNumber.from(0)
    for (let i = 0; i < utxos.length; i++) {
      accu = accu.add(utxos[i].amount)
      if (accu.gte(_amount)) {
        console.log(
          '>>>>> findUtxosUpTo return utxos.slice(0, i+1)',
          utxos.slice(0, i + 1)
        )
        return utxos.slice(0, i + 1)
      }
    }
    console.log(
      'ahould be unreachable monmay6::13:35!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!'
    )
    return utxos
  }

  return { findUtxos, findUtxosUpTo }
}
