import React, { useEffect } from 'react'
import { Flex, Box, Text, Image } from 'rebass'
import { useDispatch, useSelector } from 'react-redux'
import { formatEther } from 'ethers'
import Button from './button'
import { doubleDumpFrens, dump, fetchRegistry, useMyMetaMask } from './redux'
import { copyToClipboard, fmtDate, dedupRegistryContacts } from './util'
import { TOKENS } from './constants'

export default function Profile() {
  const dispatch = useDispatch()
  const { chainName } = useMyMetaMask()
  let {
    name,
    bayNameInput,
    shieldedAddress,
    registrationExpiry,
    registryContacts,
    frens,
    shieldedBalance
  } = useSelector(state => state)
  const valid =
    (bayNameInput?.length >= 7 && bayNameInput?.endsWith('.bay')) ||
    (name?.length >= 7 && name?.endsWith('.bay'))
  registrationExpiry = Number(registrationExpiry)
  const favorites = dedupRegistryContacts(
    registryContacts?.filter(r =>
      frens.some(f => f.shieldedAddress === r.shieldedAddress)
    ) || []
  )
  useEffect(() => {
    dispatch(fetchRegistry())
    return async () => {}
  }, [])

  return (
    <Box
      sx={{
        margin: '0vh auto auto auto',
        background: '#fff',
        padding: '0.625em',
        border: '0.1875em solid #000',
        boxShadow: '0 0.625em',
        fontSize: [16, 18, 20],
        textAlign: 'center'
      }}
    >
      <Flex
        sx={{
          fontSize: [24, 26, 28],
          fontWeight: 'bold',
          marginTop: 0,
          flexWrap: 'wrap'
        }}
      >
        Profile
      </Flex>
      <Image
        src={`https://robohash.org/${shieldedAddress}.png`}
        sx={{
          width: '70%',
          maxWidth: '10em',
          borderRadius: 10,
          verticalAlign: 'top',
          margin: '0 0.3125em 0.3125em 0'
        }}
      ></Image>
      <Box sx={{ margin: '0.625em 0' }}>
        <b>Shielded address</b>
        <Box
          style={{ wordBreak: 'break-all', cursor: 'grab' }}
          onClick={copyToClipboard.bind(null, shieldedAddress)}
        >
          {shieldedAddress}
        </Box>
      </Box>
      {name ? (
        <Box>
          <b>Name</b>
          <Box sx={{ wordBreak: 'break-all' }}>{name}</Box>
          <Box sx={{ fontSize: [10, 12], marginBottom: '1.25em' }}>
            {valid && registrationExpiry ? (
              <Text
                sx={{ margin: '0.3125em 0 0.625em 0', cursor: 'pointer' }}
                onClick={() => dispatch(dump({ showRegisterNameModal: true }))}
              >
                Expiry: {fmtDate(new Date(registrationExpiry * 1000))}
              </Text>
            ) : null}
          </Box>
        </Box>
      ) : (
        <Button
          sx={{ marginBottom: '0.3125em' }}
          onClick={() => dispatch(dump({ showRegisterNameModal: true }))}
        >
          Register <code>.bay</code> name
        </Button>
      )}
      <details
        style={{
          border: '0.0625em solid #000',
          boxShadow: '0 0.125em',
          padding: '0.625em',
          margin: '1.25em 0 0 0'
        }}
      >
        <summary style={{ cursor: 'pointer' }}>
          <b>Shielded wallet</b>
        </summary>
        <Box>
          {TOKENS.map(({ symbol, image, address }) => {
            return address[chainName] ? (
              <Box key={symbol}>
                <hr style={{ marginBottom: '0.725em' }} />
                <Image
                  src={image}
                  sx={{
                    width: ['1.4em'],
                    borderRadius: 10,
                    verticalAlign: 'top',
                    margin: '0 0.3125em 0.3125em 0'
                  }}
                ></Image>
                <Text
                  sx={{
                    minWidth: ['5em', '5.625em'],
                    display: 'inline-block'
                  }}
                >
                  {formatEther(String(shieldedBalance[symbol] || 0))} {symbol}
                </Text>
              </Box>
            ) : null
          })}
        </Box>
      </details>
      <Box sx={{ margin: '1.25em 0 0.625em 0' }}>
        <Box sx={{ margin: '0 0 0.3125em 0' }}>
          {' '}
          <b>Favorite contacts</b>
        </Box>

        {favorites.length
          ? favorites.map(r => {
              return (
                <Box
                  sx={{
                    display: 'inline-flex',
                    //   flexWrap: 'wrap',
                    marginBottom: '0.625em',
                    padding: '0.3125em',
                    border: '0.0625em solid #000',
                    boxShadow: '0 0.125em',
                    width: '100%',
                    fontSize: '14px',
                    textAlign: 'left'
                  }}
                  key={r.shieldedAddress}
                >
                  <Image
                    src={`https://robohash.org/${r.shieldedAddress}.png`}
                    sx={{
                      width: '20%',
                      minWidth: '4em',
                      borderRadius: 10,
                      verticalAlign: 'top',
                      margin: '0'
                    }}
                  ></Image>
                  <Box sx={{ position: 'relative' }}>
                    <Text
                      style={{
                        cursor: 'grab',
                        height: 'min-content',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      onClick={copyToClipboard.bind(null, r.nativeAddress)}
                      title={r.nativeAddress}
                    >
                      {' '}
                      <Text
                        style={{
                          cursor: r.name ? 'grab' : 'initial',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden'
                        }}
                        onClick={copyToClipboard.bind(null, r.name)}
                        title={r.name}
                      >
                        {' '}
                        <code>.bay</code> name: {r.name ? r.name : 'N/A'}
                      </Text>
                      Native address: {r.gno || r.ens || r.nativeAddress}
                    </Text>
                    <Text
                      style={{
                        cursor: 'grab',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        marginRight: '0.3125em'
                      }}
                      onClick={copyToClipboard.bind(null, r.shieldedAddress)}
                      title={r.shieldedAddress}
                    >
                      {' '}
                      Shielded address: {r.shieldedAddress}
                    </Text>
                    <span
                      style={{
                        position: 'absolute',
                        top: '0',
                        right: '0.3125em',
                        fontWeight: 'normal',
                        cursor: 'pointer'
                      }}
                      title="Drop from favorites"
                      onClick={() => {
                        let _frens = frens.filter(
                          f => f.shieldedAddress !== r.shieldedAddress
                        )
                        dispatch(doubleDumpFrens(_frens))
                      }}
                    >
                      ⭐
                    </span>
                  </Box>
                </Box>
              )
            })
          : 'N/A'}
      </Box>
    </Box>
  )
}
