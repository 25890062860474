module.exports = {
  chiado: {
    chainId: 10200,
    provider: 'https://rpc.chiado.gnosis.gateway.fm',
    registry: '0x346A5711BAc8A346E915A7Fc58011893AdC5AcF3',
    pool: '0xc9FA7b998E836DB501070c4d837085f0f02F0E14',
    cbay: '0xD279De972e9129Db15007ADCAafcB3993bDc412E',
    zkAssetsBaseUrl:
      'https://bafybeifnxdsnfuw2uktfaxkoyrfgzfatgm2bygtdlue32bdn4rpfaepoqy.ipfs.nftstorage.link/ipfs/bafybeifnxdsnfuw2uktfaxkoyrfgzfatgm2bygtdlue32bdn4rpfaepoqy',
    merkleTreeHeight: 23,
    startBlock: 6771191
  },
  sepolia: {
    chainId: 11155111,
    merkleTreeHeight: 23,
    startBlock: 6688502,
    noirServer: 'https://tilapialabs.xyz/prover',
    provider: 'https://ethereum-sepolia-rpc.publicnode.com',
    registry: '0xA2E433b7Ff263966F5Aa288e16FC9733012e3b1E',
    pool: '0x6C19f51207351EA1a0Af0ECD5eC9f42e484f7259',
    signMsgHashLib: '0x96F6143391a1be0c41375A97C864570CA961fA4D',
    zkAssetsBaseUrl: 'https://tilapialabs.xyz/artifacts'
  },
  gnosis: {
    chainId: 100,
    noirServer: 'http://localhost:4190', //FIXME
    provider:
      // https://rpc.circlesubi.id MAYBE?
      'https://rpc.eu-central-2.gateway.fm/v4/gnosis/archival/mainnet?apiKey=RBQ1ygy4IhH0K00AEViZOYtQIzEKAHPN.wyPL3JGGn5GJGbnv',
    registry: '0x8aB41cbE60eF21f117740d22ba540b80cB12eA5E',
    pool: '0xb898EBD15f88b247a7Fc3868DCf4B8D7db030CC1',
    signMsgHashLib: 'TODO',
    zkAssetsBaseUrl:
      'https://bafybeihfg25nm6wuoea4sj443pwprhdjiatclamsdtb3oobepxifrfcclu.ipfs.nftstorage.link/ipfs/bafybeihfg25nm6wuoea4sj443pwprhdjiatclamsdtb3oobepxifrfcclu',
    merkleTreeHeight: 23,
    startBlock: 29897069
  }
}
