import { default as ReactModal } from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import { Text, Flex, Box, Link } from 'rebass'
import { Label, Checkbox } from '@rebass/forms'
import { keccak256, toUtf8Bytes, JsonRpcProvider } from 'ethers'
import Button from './button'
import {
  deriveShieldedAccount,
  dump,
  initShieldedAccount,
  useMyMetaMask,
  checkMPECDHStatus,
  safeSelected
} from './redux'
import { copyToClipboard, getSigner, shorten, useInterval } from './util'
import {
  buildMPECDHDeployment,
  proposeMPECDHDeployment,
  mpecdh,
  getOwners,
  isMPECDHDeployed,
  isMPECDHReady
} from 'mpecdh'

export default function SafeModal() {
  const dispatch = useDispatch()
  function onClose() {
    dispatch(
      dump({
        showSafeSelectModal: false,
        showSafePropDeplMPECDHModal: false,
        showSafeExecDeplMPECDHModal: false,
        showSafeMPECDHCeremonyModal: false //correct?
      })
    )
  }
  const { chainId } = useMyMetaMask()
  const {
    showSafeSelectModal,
    showSafePropDeplMPECDHModal,
    showSafeExecDeplMPECDHModal,
    showSafeMPECDHCeremonyModal,
    mpecdhBlocking = [],
    safes = [],
    safeAddress,
    mpecdhAddress,
    safeTxHash,
    mpecdhDeployed,
    mpecdhReady,
    mpecdhStatus,
    owners,
    shieldedAccountSeed,
    showSecretSeedInput
  } = useSelector(state => state)
  //   function initShieldedAccount(seed) {
  //     const shieldedPrivateKey = keccak256(seed)
  //     dispatch(
  //       dump({
  //         shieldedPrivateKey /*FIXME*/,
  //         selectedMenu: 'fund',
  //         modalTitle: null,
  //         modalText: null,
  //         showSafeSelectModal: false,
  //         dots: true,
  //         progress: 'Initializing'
  //       })
  //     )
  //     dispatch(deriveShieldedAccount(shieldedPrivateKey))
  //   }
  // console.log('>>>safeAddress', safeAddress)
  const shortChainName =
    chainId === 100 ? 'gno' : chainId === 11155111 ? 'sep' : ''
  const safeWebWalletLink = `https://app.safe.global/transactions/queue?safe=${shortChainName}:${safeAddress}`

  const _provider =
    chainId === 100
      ? 'https://rpc.eu-central-2.gateway.fm/v4/gnosis/archival/mainnet?apiKey=RBQ1ygy4IhH0K00AEViZOYtQIzEKAHPN.wyPL3JGGn5GJGbnv'
      : chainId === 11155111
      ? 'https://ethereum-sepolia-rpc.publicnode.com'
      : null
  const provider = new JsonRpcProvider(_provider)
  // console.log('>>>>', {
  //   showSafeSelectModal,
  //   showSafePropDeplMPECDHModal,
  //   showSafeExecDeplMPECDHModal,
  //   showSafeMPECDHCeremonyModal
  // })
  useInterval(
    () => {
      if (safeAddress) {
        console.log('>>> useInterval fetch mpecdh status')
        dispatch(checkMPECDHStatus(safeAddress, provider))
      }
    },
    // null stops the interval
    showSafeSelectModal ||
      showSafePropDeplMPECDHModal ||
      showSafeExecDeplMPECDHModal ||
      showSafeMPECDHCeremonyModal
      ? 3000
      : null
  )
  // const mpecdhDeployed = await isMPECDHDeployed(
  //   safeAddress,
  //   signer.provider
  // )

  // const mpecdhAddress = calcMPECDHAddress(safeAddress)
  return (
    <ReactModal
      isOpen={
        showSafeSelectModal ||
        showSafePropDeplMPECDHModal ||
        showSafeExecDeplMPECDHModal ||
        showSafeMPECDHCeremonyModal
      }
      onRequestClose={onClose}
      contentLabel="Safe modal"
      appElement={document.getElementById('root')}
      className="responsive-modal"
    >
      <Flex
        style={{
          justifyContent: 'center',
          marginTop: window.innerHeight < 500 ? '0' : '20vh'
        }}
      >
        <Box
          sx={{
            background: '#fff',
            border: '0.1875em solid #000',
            boxShadow: '0 0.625em',
            padding: '0.625em',
            wordWrap: 'break-word',
            textAlign: 'center',
            fontSize: ['1em', '1.25em'],
            maxWidth: '32em'
          }}
        >
          <Flex sx={{ justifyContent: 'right' }}>
            <span
              style={{ margin: '-.4375em 0 0 0', cursor: 'pointer' }}
              onClick={onClose}
            >
              ✕
            </span>
          </Flex>
          <Flex sx={{ justifyContent: 'center' }}>
            <Text sx={{ fontWeight: 'bold', margin: '-.9375em 0 0.625em 0' }}>
              🔏 Safe
            </Text>
          </Flex>

          <Box sx={{ marginBottom: '0.625em' }}>
            <Flex
              style={{
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
                flexDirection: 'column'
              }}
            >
              {showSafeSelectModal && (
                <>
                  <Text>Select one of your Safes:</Text>
                  {safes.length ? (
                    <Box sx={{ margin: '0.625em 0' }}>
                      <Text></Text>
                      {safes.map(
                        ({ safeAvatar, safeAddress: _safeAddress }) => {
                          return (
                            <Label
                              key={_safeAddress}
                              sx={{
                                cursor: 'pointer',
                                justifyContent: 'center',
                                padding: [0, '0 15vw', '0 25vw']
                              }}
                            >
                              <Box
                                sx={{ width: '100%', display: 'inline-flex' }}
                              >
                                <Checkbox
                                  checked={safeAddress === _safeAddress}
                                  sx={{ color: '#000' }}
                                  onChange={async e => {
                                    if (e.target.checked) {
                                      dispatch(
                                        safeSelected(_safeAddress, provider)
                                      )
                                    } else {
                                      dispatch(
                                        dump({
                                          safeAddress: null,
                                          mpecdhAddress: null,
                                          owners: null
                                        })
                                      )
                                    }
                                  }}
                                />
                                <Box>
                                  <Text
                                    sx={{
                                      minWidth: ['3.75em', '5em'],
                                      display: 'inline-block'
                                    }}
                                  >
                                    <pre style={{ display: 'inline' }}>
                                      {' '}
                                      {shorten(_safeAddress)}
                                    </pre>
                                  </Text>
                                </Box>
                              </Box>
                            </Label>
                          )
                        }
                      )}
                    </Box>
                  ) : null}
                  {/* <Button
                    aria-label="Back"
                    style={{ margin: '0.625em 0 0 0', width: "min-content" }}
                    onClick={async () => {
                      dispatch(
                        dump({
                          showConnectModal: true,
                          showSafeSelectModal: false
                        })
                      )
                    }}
                  >
                    Back
                  </Button> */}
                  <Button
                    disabled={!safeAddress}
                    aria-label="Select"
                    style={{
                      margin: '0.625em 0 0 0',
                      width: '100%',
                      cursor: safeAddress ? 'pointer' : 'not-allowed'
                    }}
                    onClick={async () => {
                      if (safeAddress) {
                        const signer = await getSigner()
                        const owners = await getOwners(
                          safeAddress,
                          signer.provider
                        )
                        if (owners.length === 1) {
                          // mk sure ui knows we are treating this as an eoa
                          dispatch(dump({ safeAddress: null }))
                          const signedMsg = await signer.signMessage(
                            'BERMUDA_BAY_SHIELDED_ACCOUNT_V0'
                          )
                          dispatch(initShieldedAccount(signedMsg))
                          dispatch(dump({ showSafeSelectModal: false }))
                          return
                        }

                        const mpecdhDeployed = await isMPECDHDeployed(
                          safeAddress,
                          signer.provider
                        )
                        let mpecdhReady = false
                        if (mpecdhDeployed) {
                          mpecdhReady = await isMPECDHReady(
                            safeAddress,
                            signer.provider
                          )
                        }
                        console.log(
                          '>>>>onClickSelect mpecdhDeployed , mpecdhReady',
                          mpecdhDeployed,
                          mpecdhReady
                        )
                        if (mpecdhDeployed && mpecdhReady) {
                          const choreo = await mpecdh(
                            mpecdhAddress,
                            signer.provider
                          )
                          const sharedSecret = await choreo.stepX(signer)
                          dispatch(initShieldedAccount(sharedSecret))
                          dispatch(
                            dump({
                              showSafeSelectModal: false,
                              mpecdhDeployed,
                              mpecdhReady
                            })
                          )
                        } else if (mpecdhDeployed && !mpecdhReady) {
                          console.log('>>> hi1')
                          dispatch(
                            dump({
                              showSafeMPECDHCeremonyModal: true,
                              showSafeSelectModal: false,
                              mpecdhDeployed,
                              mpecdhReady
                            })
                          )
                        } else {
                          dispatch(
                            dump({
                              showSafePropDeplMPECDHModal: true,
                              showSafeSelectModal: false,
                              mpecdhDeployed,
                              mpecdhReady
                            })
                          )
                        }
                      }
                    }}
                  >
                    Sign in
                  </Button>
                </>
              )}

              {showSafePropDeplMPECDHModal && (
                <>
                  <Text sx={{ marginBottom: '0.625em' }}>
                    The selected Safe{' '}
                    <span
                      style={{ cursor: 'grab' }}
                      onClick={copyToClipboard.bind(null, safeAddress)}
                      title="Selected Safe"
                    >
                      <pre style={{ display: 'inline' }}>
                        {shorten(safeAddress)}
                      </pre>
                    </span>{' '}
                    does not yet have a MPECDH contract. Proceed with proposing
                    its deployment at{' '}
                    <span
                      style={{ cursor: 'grab' }}
                      onClick={copyToClipboard.bind(null, mpecdhAddress)}
                      title="Safe's MPECDH address"
                    >
                      <pre style={{ display: 'inline' }}>
                        {shorten(mpecdhAddress)}
                      </pre>
                    </span>{' '}
                    :
                  </Text>
                  <pre
                    style={{
                      textAlign: 'left',
                      overflow: 'scroll',
                      width: '100%',
                      fontSize: '.75em'
                    }}
                  >
                    {JSON.stringify(
                      buildMPECDHDeployment(safeAddress, owners),
                      null,
                      2
                    )}
                  </pre>
                  {/* <Button
                    aria-label="Back"
                    style={{ margin: '0.625em 0 0 0', width: 'auto' }}
                    onClick={async () => {
                      dispatch(
                        dump({
                          showSafeSelectModal: true,
                          showSafePropDeplMPECDHModal: false
                        })
                      )
                    }}
                  >
                    Back
                  </Button> */}
                  <Button
                    aria-label="ProposeDeployMPECDH"
                    disabled={!safeAddress}
                    style={{
                      margin: '0.625em 0 0 0',
                      width: 'auto',
                      cursor: safeAddress ? 'pointer' : 'not-allowed'
                    }}
                    onClick={async () => {
                      const signer = await getSigner()
                      const { safeTxHash } = await proposeMPECDHDeployment(
                        signer,
                        safeAddress
                      )
                      dispatch(
                        dump({
                          safeTxHash,
                          showSafePropDeplMPECDHModal: false,
                          showSafeExecDeplMPECDHModal: true
                        })
                      )
                    }}
                  >
                    Propose
                  </Button>
                </>
              )}

              {showSafeExecDeplMPECDHModal && (
                <>
                  <Text sx={{ marginBottom: '0.625em' }}>
                    To complete the MPECDH deployment have all Safe owners
                    confirm transaction{' '}
                    <span
                      style={{ cursor: 'grab' }}
                      onClick={copyToClipboard.bind(null, safeTxHash)}
                      title="Safe tx hash"
                    >
                      <pre style={{ display: 'inline' }}>
                        {shorten(safeTxHash)}
                      </pre>
                    </span>
                    , then execute it. Do this via{' '}
                    <Link
                      href={safeWebWalletLink}
                      target="_blank"
                      style={{
                        textDecoration: 'none',
                        cursor: 'pointer'
                      }}
                    >
                      Safe's web wallet
                    </Link>
                    .
                  </Text>
                  {/* <Button
                    aria-label="Back"
                    style={{ margin: '0.625em 0 0 0', width: 'auto' }}
                    onClick={async () => {
                      dispatch(
                        dump({
                          showSafeExecDeplMPECDHModal: false,
                          showSafePropDeplMPECDHModal: true
                        })
                      )
                    }}
                  >
                    Back
                  </Button> */}
                  <Button
                    disabled={!mpecdhDeployed}
                    aria-label="Ceremony"
                    style={{
                      margin: '0.625em 0 0 0',
                      width: 'auto',
                      cursor: mpecdhDeployed ? 'pointer' : 'not-allowed'
                    }}
                    onClick={async () => {
                      if (mpecdhDeployed) {
                        dispatch(
                          dump({
                            showSafeExecDeplMPECDHModal: false,
                            showSafeMPECDHCeremonyModal: true
                          })
                        )
                      }
                    }}
                  >
                    Continue
                  </Button>
                </>
              )}

              {showSafeMPECDHCeremonyModal && (
                <>
                  <Text sx={{ marginBottom: '0.625em' }}>
                    Have all Safe signers contribute to the ceremony in turns...
                  </Text>
                  {mpecdhBlocking.length ? (
                    <>
                      <Text>Blocking round contributors:</Text>
                      {mpecdhBlocking.map(b => (
                        <Text>{b}</Text>
                      ))}
                    </>
                  ) : null}
                  {/* <Button
                    aria-label="Back"
                    style={{ margin: '0.625em 0 0 0', width: 'auto' }}
                    onClick={async () => {
                      dispatch(
                        dump({
                          showSafeExecDeplMPECDHModal: mpecdhDeployed
                            ? false
                            : true,
                          showSafeSelectModal: mpecdhDeployed ? true : false,
                          showSafeMPECDHCeremonyModal: false
                        })
                      )
                    }}
                  >
                    Back
                  </Button> */}
                  <Button
                    aria-label="safe-connect"
                    disabled={mpecdhStatus === undefined || mpecdhStatus === 2}
                    style={{
                      margin: '0.625em 0 0 0',
                      width: 'auto',
                      // enable connect/contribute btn if mpecdh status !idle
                      cursor:
                        (mpecdhStatus !== undefined && mpecdhStatus !== 2) ||
                        mpecdhReady
                          ? 'pointer'
                          : 'not-allowed'
                    }}
                    onClick={async () => {
                      dispatch(
                        dump({
                          dots: true,
                          progress: mpecdhReady
                            ? ''
                            : 'Contributing to ceremony'
                        })
                      )
                      const signer = await getSigner()
                      const choreo = await mpecdh(
                        mpecdhAddress,
                        signer.provider
                      )
                      if (mpecdhReady) {
                        const sharedSecret = await choreo.stepX(signer)
                        dispatch(initShieldedAccount(sharedSecret))
                        dispatch(
                          dump({
                            showSafeMPECDHCeremonyModal: false,
                            dots: false
                          })
                        )
                      } else if (mpecdhStatus === 3 || mpecdhStatus === 1) {
                        // if contributor status null (3) or ok (1)
                        let tx
                        if (mpecdhStatus === 3) {
                          tx = await choreo.step0(signer)
                        } else {
                          tx = await choreo.stepN(signer)
                        }
                        dispatch(
                          dump({
                            progress: 'Awaiting transaction confirmation'
                          })
                        )
                        await tx.wait()
                        dispatch(dump({ dots: false }))
                      } else {
                        console.log('>>> mpecdhStatus', mpecdhStatus)
                        alert(
                          'have the other signers sign in and contribute ツ'
                        )
                        dispatch(dump({ dots: false }))
                      }
                    }}
                  >
                    {mpecdhReady ? 'Connect' : 'Contribute'}
                  </Button>
                </>
              )}
            </Flex>
          </Box>
        </Box>
      </Flex>
    </ReactModal>
  )
}
