import { default as ReactModal } from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import { Text, Flex, Box, Link } from 'rebass'
import { Input } from '@rebass/forms'
import Button from './button'
import { dump, switchNetwork, useMyMetaMask } from './redux'

export default function ChainModal() {
  const dispatch = useDispatch()
  const { chainName } = useMyMetaMask()
  const { showChainModal, customRpcUrl, selectedChain } = useSelector(
    state => state
  )

  return (
    <ReactModal
      isOpen={showChainModal}
      onRequestClose={() => dispatch(dump({ showChainModal: false }))}
      contentLabel="chain modal"
      appElement={document.getElementById('root')}
      className="responsive-modal"
    >
      <Flex
        style={{
          justifyContent: 'center',
          marginTop: window.innerHeight < 500 ? '0' : '20vh'
        }}
      >
        <Box
          sx={{
            background: '#fff',
            border: '0.1875em solid #000',
            boxShadow: '0 0.625em',
            padding: '0.625em',
            wordWrap: 'break-word',
            textAlign: 'center',
            fontSize: ['1em', '1.25em']
          }}
        >
          <Text sx={{ fontWeight: 'bold', marginBottom: '0.625em' }}>
            ⛓️ Chains
          </Text>
          <Box sx={{ marginBottom: '0.3125em' }}>
            <Text>
              By default RPC is powered by{' '}
              <Link
                href="https://gateway.fm/"
                target="_blank"
                style={{
                  textDecoration: 'none',
                  cursor: 'pointer'
                }}
              >
                gateway.fm
              </Link>{' '}
              but you can opt in a custom provider.
            </Text>
            <Flex style={{ flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
              <Button
                aria-label="Sepolia"
                theme={selectedChain === 'sepolia' ? 'dark' : 'lite'}
                style={{ margin: '1.25em 0 0 0' }}
                onClick={() => dispatch(dump({ selectedChain: 'sepolia' }))}
              >
                Sepolia
              </Button>
              <Button
                aria-label="Gnosis"
                theme={selectedChain === 'gnosis' ? 'dark' : 'lite'}
                style={{ margin: '0 0 0 0' /*, cursor: 'not-allowed' */ }}
                onClick={() => dispatch(dump({ selectedChain: 'gnosis' }))}
              >
                Gnosis
              </Button>
            </Flex>
          </Box>
          <Flex>
            <Input
              id="custom-rpc-url"
              placeholder="Custom RPC URL"
              onChange={e => dispatch(dump({ customRpcUrl: e.target.value }))}
              value={customRpcUrl || ''}
              bg="#fff"
              sx={{ margin: '0 0 0.625em 0', fontSize: [16, 18, 20] }}
            />
          </Flex>
          <Button
            disabled={false}
            aria-label="Switch"
            style={{
              margin: '0 0 0.625em 0',
              cursor: 'pointer'
            }}
            onClick={() => dispatch(switchNetwork())}
          >
            Switch
          </Button>
        </Box>
      </Flex>
    </ReactModal>
  )
}
