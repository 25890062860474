import React from 'react'
import { Flex, Text, Box, Link } from 'rebass'
import { useSelector, useDispatch } from 'react-redux'
import { dump, useMyMetaMask } from './redux'

export default function Menu() {
  const dispatch = useDispatch()
  const { chainName } = useMyMetaMask()
  const { selectedMenu, shieldedAddress, isRegistered, burgerMenuOpen } =
    useSelector(state => state)
  return (
    <Flex
      sx={{
        color: '#000',
        padding: '0 0.625em',
        margin: 0,
        fontSize: [16, 18, 20],
        flexWrap: 'wrap'
      }}
    >
      <Text
        onClick={() =>
          dispatch(
            dump({
              selectedMenu:
                isRegistered && shieldedAddress ? 'fund' : 'registry'
            })
          )
        }
        style={{
          margin: '0 0.625em',
          cursor: isRegistered && shieldedAddress ? 'pointer' : 'default',
          fontWeight: selectedMenu === 'fund' ? 'bold' : 'normal'
        }}
      >
        Fund
      </Text>
      <Text
        onClick={() =>
          dispatch(
            dump({
              selectedMenu:
                isRegistered && shieldedAddress ? 'transfer' : 'registry'
            })
          )
        }
        style={{
          margin: '0 0.625em',
          cursor: isRegistered && shieldedAddress ? 'pointer' : 'default',
          fontWeight: selectedMenu === 'transfer' ? 'bold' : 'normal'
        }}
      >
        Transfer
      </Text>
      <Text
        onClick={() =>
          dispatch(
            dump({
              selectedMenu:
                isRegistered && shieldedAddress ? 'withdraw' : 'registry'
            })
          )
        }
        style={{
          margin: '0 0.625em',
          cursor: isRegistered && shieldedAddress ? 'pointer' : 'default',
          fontWeight: selectedMenu === 'withdraw' ? 'bold' : 'normal'
        }}
      >
        Withdraw
      </Text>

      <Box
        id="burgerMenu"
        sx={{ marginLeft: 'auto', cursor: 'pointer', position: 'relative' }}
        onClick={() => dispatch(dump({ burgerMenuOpen: !burgerMenuOpen }))}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2.1"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="3" y1="12" x2="21" y2="12"></line>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="3" y1="18" x2="21" y2="18"></line>
        </svg>
        {burgerMenuOpen ? (
          <Box
            onClick={() => dispatch(dump({ burgerMenuOpen: false }))}
            style={{
              margin: 0,
              cursor: 'pointer',
              border: '0.1875em solid #000',
              boxShadow: '0 0.625em',
              background: '#fff',
              padding: '0.3125em 0.625em',
              position: 'absolute',
              top: '2em',
              right: 0
            }}
          >
            <Text
              style={{
                fontWeight: selectedMenu === 'profile' ? 'bold' : 'normal',
                margin: '0 0 0 0',
                cursor: shieldedAddress ? 'pointer' : 'not-allowed'
              }}
              onClick={() =>
                shieldedAddress
                  ? dispatch(
                      dump({ selectedMenu: shieldedAddress ? 'profile' : null })
                    )
                  : null
              }
            >
              Profile
            </Text>
            <Text
              style={{
                fontWeight: selectedMenu === 'community' ? 'bold' : 'normal',
                margin: '0 0 0 0',
                cursor: shieldedAddress ? 'pointer' : 'not-allowed'
              }}
              onClick={() =>
                shieldedAddress
                  ? dispatch(
                      dump({
                        selectedMenu: shieldedAddress ? 'community' : null
                      })
                    )
                  : null
              }
            >
              Community
            </Text>
            <Text
              style={{
                fontWeight: selectedMenu === 'history' ? 'bold' : 'normal',
                margin: '0 0 0 0'
              }}
              onClick={() =>
                dispatch(
                  dump({
                    selectedMenu: 'history',
                    viewingKeyDetailsOpen: shieldedAddress ? false : true
                  })
                )
              }
            >
              History
            </Text>
            <Text
              style={{
                display: chainName === 'sepolia' ? 'initial' : 'none',
                fontWeight: selectedMenu === 'faucet' ? 'bold' : 'normal',
                margin: '0 0 0 0',
                cursor: 'pointer'
              }}
              onClick={() => dispatch(dump({ selectedMenu: 'faucet' }))}
            >
              Faucet
            </Text>
            <Link
              style={{
                display: 'block',
                fontWeight: 'normal',
                margin: '0 0 0 0',
                cursor: 'pointer',
                textDecoration: 'none',
                color: '#000'
              }}
              target="_blank"
              href="https://bermudabaydocs.vercel.app"
            >
              Docs
            </Link>
          </Box>
        ) : null}
      </Box>
    </Flex>
  )
}
