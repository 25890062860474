import { default as ReactModal } from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import { Text, Flex, Box } from 'rebass'
import { Input } from '@rebass/forms'
import Button from './button'
import { initShieldedAccount, dump, safeConnectFlow } from './redux'
import { getSigner } from './util'

export default function ConnectModal() {
  const dispatch = useDispatch()
  function onClose() {
    dispatch(dump({ showConnectModal: false }))
  }
  const { showConnectModal, shieldedAccountSeed, showSecretSeedInput } =
    useSelector(state => state)

  return (
    <ReactModal
      isOpen={showConnectModal}
      onRequestClose={onClose}
      contentLabel="connect modal"
      appElement={document.getElementById('root')}
      className="responsive-modal"
    >
      <Flex
        style={{
          justifyContent: 'center',
          marginTop: window.innerHeight < 500 ? '0' : '20vh'
        }}
      >
        <Box
          sx={{
            background: '#fff',
            border: '0.1875em solid #000',
            boxShadow: '0 0.625em',
            padding: '0.625em',
            wordWrap: 'break-word',
            textAlign: 'center',
            fontSize: ['1em', '1.25em'],
            maxWidth: '32em'
          }}
        >
          <Flex sx={{ justifyContent: 'right' }}>
            <span
              style={{ margin: '-.4375em 0 0 0', cursor: 'pointer' }}
              onClick={onClose}
            >
              ✕
            </span>
          </Flex>
          <Flex sx={{ justifyContent: 'center' }}>
            <Text sx={{ fontWeight: 'bold', margin: '-.9375em 0 0.625em 0' }}>
              🔌 Connect
            </Text>
          </Flex>
          <Box sx={{ marginBottom: '0.625em' }}>
            <Text>Provide a shielded account secret seed.</Text>
            <Flex style={{ flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
              <Button
                aria-label="Default"
                style={{ margin: '1.25em 0 0 0' }}
                onClick={async () => {
                  const signer = await getSigner()
                  // WARNING: this signedMsg must be treated confidentially!
                  const signedMsg = await signer.signMessage(
                    'BERMUDA_BAY_SHIELDED_ACCOUNT_V0'
                  )
                  dispatch(initShieldedAccount(signedMsg))
                  dispatch(dump({ safeAddress: null }))
                }}
              >
                EOA
              </Button>
              <Text sx={{ margin: '0.625em 0 0 0', fontSize: '0.75em' }}>
                The seed is derived from a simple message signature.
              </Text>
              <Button
                aria-label="Safe"
                style={{ margin: '1.25em 0 0 0' }}
                onClick={async () => {
                  dispatch(safeConnectFlow())
                }}
              >
                Safe
              </Button>
              <Text sx={{ margin: '0.625em 0 0 0', fontSize: '0.75em' }}>
                The seed is obtained through a multi-party key exchange amongst
                all owners of a Safe.
              </Text>
              {/* <Button
                aria-label="Custom"
                style={{ margin: '1.25em 0 0 0' }}
                onClick={() => {
                  if (shieldedAccountSeed) {
                    initShieldedAccount(toUtf8Bytes(shieldedAccountSeed))
                    dispatch(dump({ shieldedAccountSeed: null }))
                  } else {
                    dispatch(dump({ showSecretSeedInput: true }))
                  }
                }}
              >
                {shieldedAccountSeed ? 'Connect' : 'Custom'}
              </Button>
              <Text sx={{ margin: '0.625em 0 0 0', fontSize: '0.75em' }}>
                You provide a custom seed.
              </Text> */}
            </Flex>
          </Box>
          {showSecretSeedInput ? (
            <Flex>
              <Input
                type="password"
                id="shielded-account-secret-seed"
                placeholder="Secret seed"
                onChange={e =>
                  dispatch(dump({ shieldedAccountSeed: e.target.value }))
                }
                value={shieldedAccountSeed || ''}
                bg="#fff"
                sx={{ margin: '0.625em 0 0.625em 0', fontSize: [16, 18, 20] }}
              />
            </Flex>
          ) : null}
        </Box>
      </Flex>
    </ReactModal>
  )
}
