import React from 'react'
import { Flex, Box } from 'rebass'
import { Input, Textarea, Checkbox, Label } from '@rebass/forms'
import { useDispatch, useSelector } from 'react-redux'
import Button from './button'
import ShieldedTokenInput from './shielded-token-input'
import { dump, transfer, useMyMetaMask } from './redux'
import { formatUnits, parseUnits } from 'ethers'
import { BigNumber } from '@ethersproject/bignumber'
import { TOKENS } from './constants'
import { gte, prettierBalance } from './util'

function validate(shieldedRecipient, shieldedAmount, shieldedBalance) {
  //TODO gte() needs decimals as 3rd param
  return (
    !!shieldedRecipient &&
    /^\d+(\.\d+)?$/.test(shieldedAmount) &&
    gte(shieldedBalance, shieldedAmount) &&
    Number(shieldedAmount) !== 0
  )
}

export default function Transfer() {
  const { account, chainName } = useMyMetaMask()
  const {
    transferShieldedRecipient,
    shieldedTokenAmount,
    transferNote,
    shieldedAddress,
    transferUseGelatoRelay,
    selectedToken,
    shieldedBalance,
    gelatoRelayFeeEstimates,
    frens
  } = useSelector(state => state)
  const dispatch = useDispatch()
  // const valid = validate(
  //   transferShieldedRecipient,
  //   shieldedTokenAmount,
  //   shieldedBalance[selectedToken]
  // )
  const token = TOKENS.find(({ symbol }) => symbol === selectedToken)
  const gelatoFee = BigNumber.from(
    String(gelatoRelayFeeEstimates[selectedToken] || 0)
  )
  const tokenAmount = shieldedTokenAmount
    ? BigNumber.from(
        String(parseUnits(prettierBalance(shieldedTokenAmount), token.decimals))
      )
    : BigNumber.from(0)
  const total = formatUnits(
    String(transferUseGelatoRelay ? tokenAmount.add(gelatoFee) : tokenAmount),
    token.decimals
  ).slice(0, 6)
  const valid = validate(
    transferShieldedRecipient,
    transferUseGelatoRelay ? total : shieldedTokenAmount,
    shieldedBalance[selectedToken]
  )
  return (
    <Box
      sx={{
        maxWidth: '24em',
        border: '0.1875em solid #000',
        boxShadow: '0 0.625em',
        padding: '0.625em'
      }}
    >
      <Flex
        sx={{
          fontSize: [24, 26, 28],
          fontWeight: 'bold',
          marginBottom: '0.625em',
          flexWrap: 'wrap'
        }}
      >
        Transfer
      </Flex>
      <Input
        type="text"
        id="transfer-shielded-recipient"
        placeholder="Shielded recipient"
        title="Shielded address or .bay name"
        onChange={e =>
          dispatch(dump({ transferShieldedRecipient: e.target.value }))
        }
        value={transferShieldedRecipient ?? ''}
        bg="#fff"
        sx={{
          marginBottom: '0.625em',
          fontSize: [16, 18, 20],
          textAlign: 'center'
        }}
        list={frens.length ? 'favorite-contacts' : null}
        autoComplete="off"
      />
      <datalist id="favorite-contacts">
        {frens.map(f => {
          const val = f.name || f.shieldedAddress
          return (
            <option key={val} value={val}>
              {val}
            </option>
          )
        })}
      </datalist>
      <ShieldedTokenInput />
      <Textarea
        value={transferNote}
        rows={1}
        placeholder="Note (optional)"
        maxLength={255}
        sx={{
          textAlign: 'center',
          resize: 'none',
          fontSize: [12, 14],
          marginBottom: '0.625em'
        }}
        onChange={e => dispatch(dump({ transferNote: e.target.value }))}
      ></Textarea>
      {chainName === 'sepolia' && selectedToken === 'GNO' ? null : (
        <Box>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Label
              sx={{
                display: 'inline-grid',
                pointerEvents: 'none',
                margin: '0 0 0.3125em 0',
                width: 'auto',
                fontSize: [16, 18, 20]
              }}
            >
              <Checkbox
                id="transferUseGelatoRelay"
                checked={transferUseGelatoRelay}
                sx={{
                  color: '#000',
                  pointerEvents: 'auto',
                  cursor: 'pointer',
                  marginTop: [0, 0, '0.125em']
                }}
                onChange={e => {
                  const state = { transferUseGelatoRelay: e.target.checked }
                  if (!e.target.checked) {
                    state.modalTitle = '⚠️ Warning'
                    state.modalText =
                      'If not using Gelato Relay you are directly interacting with our contract.'
                  }
                  dispatch(dump(state))
                }}
              />
              {selectedToken === 'GNO' ? null : 'Use Gelato Relay'}
            </Label>
            <Box sx={{ fontSize: [10, 12], alignSelf: 'center' }}>
              {formatUnits(gelatoFee.toHexString(), token.decimals).slice(0, 6)}{' '}
              {selectedToken}
            </Box>
          </Flex>
          <Flex
            sx={{ marginBottom: '0.3125em', justifyContent: 'space-between' }}
          >
            <span style={{ fontWeight: 'bold', marginRight: '0.3125em' }}>
              TOTAL
            </span>
            <span>
              {total} {selectedToken}
            </span>
          </Flex>
        </Box>
      )}

      <Button
        disabled={!(account && shieldedAddress && valid)}
        aria-label="Transfer"
        style={{
          cursor: valid ? 'pointer' : 'not-allowed'
        }}
        onClick={() =>
          dispatch(transfer(shieldedTokenAmount, transferShieldedRecipient))
        }
      >
        Transfer
      </Button>
    </Box>
  )
}
