module.exports = { prove2, prove16 }

async function loadDeps() {
  const { groth16 } = await import('snarkjs')
  const { utils: ffutils } = await import('ffjavascript')
  return { groth16, ffutils }
}

async function prove2(input, baseUrl) {
  const { groth16, ffutils } = await loadDeps()
  const { proof } = await groth16.fullProve(
    ffutils.stringifyBigInts(input),
    `${baseUrl}/transaction2.wasm`,
    `${baseUrl}/transaction2.zkey`
  )
  return proof
}

async function prove16(input, baseUrl) {
  const { groth16, ffutils } = await loadDeps()
  const { proof } = await groth16.fullProve(
    ffutils.stringifyBigInts(input),
    `${baseUrl}/transaction16.wasm`,
    `${baseUrl}/transaction16.zkey`
  )
  return proof
}
