import React from 'react'
import { Flex, Box } from 'rebass'
import { Input, Checkbox, Label, Textarea } from '@rebass/forms'
import { useDispatch, useSelector } from 'react-redux'
import Button from './button'
import StandardTokenInput from './std-token-input'
import { dump, fund, useMyMetaMask } from './redux'
import { gte } from './util'

function validate(shieldedRecipient, amount, balance) {
  // console.log("valid gte(balance, amount)", gte(balance, amount))
  return (
    !!shieldedRecipient &&
    /^\d+(\.\d+)?$/.test(amount) &&
    gte(balance, amount) &&
    Number(amount) !== 0
  )
}

export default function Fund() {
  const { account } = useMyMetaMask()
  const {
    fundShieldedRecipient,
    tokenAmount,
    fundNote,
    shieldedAddress,
    selectedToken,
    standardBalance,
    frens = [],
    name
  } = useSelector(state => state)
  const dispatch = useDispatch()
  const valid = validate(
    fundShieldedRecipient || shieldedAddress,
    tokenAmount,
    standardBalance[selectedToken]
  )
  return (
    <Box
      sx={{
        maxWidth: '24em',
        border: '0.1875em solid #000',
        boxShadow: '0 0.625em',
        padding: '0.625em'
      }}
    >
      <Flex
        sx={{
          fontSize: [24, 26, 28],
          fontWeight: 'bold',
          marginBottom: '0.625em',
          flexWrap: 'wrap'
        }}
      >
        Fund
      </Flex>

      <Input
        type="text"
        id="fund-shielded-recipient"
        placeholder="Shielded recipient"
        title="Shielded address or .bay name"
        onChange={e =>
          dispatch(dump({ fundShieldedRecipient: e.target.value }))
        }
        value={fundShieldedRecipient ?? name ?? shieldedAddress ?? ''}
        bg="#fff"
        sx={{
          marginBottom: '0.625em',
          fontSize: [16, 18, 20],
          textAlign: 'center'
        }}
        list={frens.length ? 'favorite-contacts' : null}
        autoComplete="off"
      />
      <datalist id="favorite-contacts">
        {frens?.map(f => {
          const val = f.name || f.shieldedAddress
          return (
            <option key={val} value={val}>
              {val}
            </option>
          )
        })}
      </datalist>
      <StandardTokenInput />
      <Textarea
        value={fundNote}
        rows={1}
        placeholder="Note (optional)"
        maxLength={255}
        sx={{
          textAlign: 'center',
          resize: 'none',
          fontSize: [12, 14],
          marginBottom: '0.625em'
        }}
        onChange={e => dispatch(dump({ fundNote: e.target.value }))}
      ></Textarea>
      <Button
        disabled={!(account && valid)}
        aria-label="Fund"
        style={{
          cursor: valid ? 'pointer' : 'not-allowed'
        }}
        onClick={() =>
          dispatch(fund(tokenAmount, fundShieldedRecipient || shieldedAddress))
        }
      >
        Fund
      </Button>
    </Box>
  )
}
