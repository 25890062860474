import { default as ReactModal } from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import { Text, Flex, Box } from 'rebass'
import Button from './button'
import { dump, checkStxHashInterval /*, checkSafeAllowance*/ } from './redux'

export default function Modal() {
  const dispatch = useDispatch()
  function onClose() {
    dispatch(dump({ modalText: null, modalTitle: null }))
    dispatch(checkStxHashInterval(false))
    // dispatch(checkSafeAllowance(false))
  }
  const { modalText, modalTitle } = useSelector(state => state)
  return (
    <ReactModal
      isOpen={!!modalText}
      onRequestClose={onClose}
      contentLabel="info overlay modal"
      appElement={document.getElementById('root')}
      className="responsive-modal"
    >
      <Flex
        style={{
          justifyContent: 'center',
          marginTop: window.innerHeight < 300 ? '0' : '20vh'
        }}
      >
        <Box
          sx={{
            background: '#fff',
            border: '0.1875em solid #000',
            boxShadow: '0 0.625em',
            padding: '0.625em',
            wordWrap: 'break-word',
            textAlign: 'center',
            fontSize: ['1em', '1.25em']
          }}
        >
          <Flex sx={{ justifyContent: 'right' }}>
            <span
              style={{ margin: '-.4375em 0 0 0', cursor: 'pointer' }}
              onClick={onClose}
            >
              ✕
            </span>
          </Flex>
          <Flex sx={{ justifyContent: 'center' }}>
            <Text sx={{ fontWeight: 'bold', margin: '-.9375em 0 0.625em 0' }}>
              {modalTitle}
            </Text>
          </Flex>
          <Text sx={{ font: '1em Open Sans, normal', marginBottom: '0.625em' }}>
            {modalText}
          </Text>
          <div style={{ clear: 'both' }}></div>
        </Box>
      </Flex>
    </ReactModal>
  )
}
