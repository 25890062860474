import React, { useEffect } from 'react'
import { Flex, Box, Text } from 'rebass'
import { Input } from '@rebass/forms'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import Button from './button'
import {
  history,
  useMyMetaMask,
  dump,
  genViewingKey,
  setViewingKey
} from './redux'
import { shorten, copyToClipboard, prettierBalance } from './util'
import { TOKENS } from './constants'

export default function History() {
  const dispatch = useDispatch()
  const { chainName } = useMyMetaMask()
  const isSmol = useMediaQuery({ query: '(max-width: 41.25em)' })
  let {
    utxosh = [],
    viewingKey,
    compoundViewingKey,
    shieldedAddress,
    batchViewingKey,
    viewingKeyDetailsOpen
  } = useSelector(state => state)
  useEffect(() => {
    if (shieldedAddress) {
      dispatch(history())
    }
    return async () => {}
  }, [])
  return (
    <Box
      sx={{
        maxWidth: '100%',
        border: '0.1875em solid #000',
        boxShadow: '0 0.625em',
        padding: '0.625em'
      }}
    >
      <Flex
        sx={{
          fontSize: [24, 26, 28],
          fontWeight: 'bold',
          marginTop: 0,
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}
      >
        <span> History</span>
        <Box
          sx={{
            cursor: 'pointer',
            fontWeight: 'normal',
            fontSize: [16, 18]
          }}
          onClick={() => dispatch(history())}
          title="Reload history"
        >
          {' '}
          {'↻'}
        </Box>
      </Flex>
      <Box sx={{ margin: '0.625em 0 0.625em 0', fontSize: '1em' }}>
        <details
          open={viewingKeyDetailsOpen}
          style={{
            border: '0.0625em solid #000',
            boxShadow: '0 0.125em',
            padding: '0.625em'
          }}
        >
          <summary style={{ marginBottom: '0.625em', cursor: 'pointer' }}>
            <b>Viewing key</b>
          </summary>
          <Text sx={{ marginBottom: '0.625em', textAlign: 'center' }}>
            Enter a viewing key to display a particular transaction set.
          </Text>
          <Input
            type="text"
            id="viewing-key-input"
            placeholder="Viewing key"
            title="Viewing key"
            onChange={e => dispatch(setViewingKey(e.target.value))}
            value={viewingKey ?? ''}
            bg="#fff"
            sx={{ marginBottom: '0.625em' }}
          />

          {shieldedAddress &&
          utxosh.length &&
          utxosh.every(u => u.partialViewingKey) ? (
            <Box>
              <hr></hr>
              <Text sx={{ marginBottom: '0.625em', textAlign: 'center' }}>
                To generate a viewing key for a single transaction click one of
                the 🔐 icons within the table below.
              </Text>
              <hr></hr>
              <Text sx={{ marginBottom: '0.625em', textAlign: 'center' }}>
                To generate a viewing key for multiple transactions click{' '}
                <b>Batch</b>, then the 🔐 icons of the respective transactions,
                and finally <b>Generate</b>.
              </Text>
              <Flex style={{ justifyContent: 'right' }}>
                <Button
                  sx={{ width: 'auto', fontSize: '1em' }}
                  onClick={() => dispatch(genViewingKey())}
                >
                  {compoundViewingKey || batchViewingKey ? 'Generate' : 'Batch'}
                </Button>
              </Flex>
            </Box>
          ) : null}
        </details>
      </Box>

      <Box sx={{ margin: '1.25em 0 0 0', fontSize: '0.875em' }}>
        {utxosh.length ? (
          <Box
            sx={{
              display: isSmol ? 'none' : 'inline-flex',
              margin: '0 0 0.625em 0'
            }}
          >
            <span style={{ width: '10em' }}>
              {' '}
              <b>Date</b>{' '}
            </span>
            <span style={{ width: '5.625em' }}>
              {' '}
              <b>Type</b>{' '}
            </span>
            <span style={{ width: '8.7em' }}>
              {' '}
              <b>Token</b>{' '}
            </span>
            <span style={{ width: '9em' }}>
              {' '}
              <b>Recipient</b>{' '}
            </span>
            <span style={{ width: '12em' }}>
              {' '}
              <b>Note</b>{' '}
            </span>
            <span> </span>
          </Box>
        ) : null}

        {utxosh.map(u => {
          const tokenAddress =
            '0x' + Buffer.from(u.token).toString('hex').toLowerCase()
          const token = TOKENS.find(({ address }) => {
            return address[chainName]?.toLowerCase() === tokenAddress
          })
          return (
            <Box
              sx={{
                display: 'inline-flex',
                flexWrap: 'wrap',
                marginBottom: '0.625em',
                padding: '0.3125em',
                border: '0.0625em solid #000',
                boxShadow: '0 0.125em',
                width: '100%'
              }}
              key={JSON.stringify(u)}
            >
              <span style={{ width: '10em' }} title="Date">
                {' '}
                {u.date
                  .toLocaleString('en-US', {
                    dateStyle: 'short',
                    timeStyle: 'short'
                  })
                  .replace(',', '')
                  .replace(/ (\d:)/, ' 0$1')}
              </span>
              <span style={{ width: '5.625em' }} title="Type">
                <a
                  target="_blank"
                  href={`https://sepolia.etherscan.io/tx/${u.tx}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {u.type.endsWith('transfer')
                    ? 'Transfer'
                    : u.type[0].toUpperCase() + u.type.slice(1)}
                </a>
              </span>
              {u.type === 'withdraw' ? (
                <span style={{ width: '8.5em' }} title="Token">
                  🚧WIP🚧
                </span>
              ) : (
                <span style={{ width: '8.5em' }} title="Token">
                  {u.type === 'outbound transfer' || u.type === 'withdraw'
                    ? '−'
                    : '+'}
                  {prettierBalance(u.amount)} {token?.symbol}
                </span>
              )}
              {u.type === 'withdraw' ? (
                <span
                  onClick={copyToClipboard.bind(null, u.to)}
                  style={{ cursor: 'grab', width: '8.9em' }}
                  title="Recipient"
                >
                  🚧WIP🚧
                </span>
              ) : (
                <span
                  onClick={copyToClipboard.bind(null, u.to)}
                  style={{ cursor: 'grab', width: '8.9em' }}
                  title="Recipient"
                >
                  {' '}
                  {u.toBayName || shorten(u.to)}{' '}
                </span>
              )}
              <span
                style={{ overflowWrap: 'anywhere', width: 'auto' }}
                title="Note"
              >
                {' '}
                {u.note}{' '}
              </span>
              {u.partialViewingKey ? (
                <span
                  className="key-lock"
                  style={{ cursor: 'grab', marginLeft: 'auto' }}
                  title="Get this transaction's viewing key"
                  onClick={e => {
                    if (batchViewingKey) {
                      e.target.batched = !e.target.batched
                      if (e.target.batched) {
                        dispatch(
                          dump({
                            compoundViewingKey:
                              (compoundViewingKey || '0x') +
                              u.partialViewingKey.replace('0x', '')
                          })
                        )
                        e.target.title = '✔️ Batched'
                        e.target.innerText = '✔️'
                        setTimeout(() => {
                          e.target.title = ''
                        }, 4190)
                      } else {
                        e.target.innerText = '🔐'
                        dispatch(
                          dump({
                            compoundViewingKey: compoundViewingKey.replace(
                              u.partialViewingKey.replace('0x', ''),
                              ''
                            )
                          })
                        )
                      }
                    } else if (!compoundViewingKey) {
                      dispatch(
                        dump({
                          modalTitle: '🔐 Viewing key',
                          modalText: (
                            <Box>
                              <span
                                style={{ cursor: 'grab' }}
                                onClick={copyToClipboard.bind(
                                  null,
                                  u.partialViewingKey
                                )}
                              >
                                {u.partialViewingKey}
                              </span>
                            </Box>
                          )
                        })
                      )
                    }
                  }}
                >
                  🔐
                </span>
              ) : null}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
