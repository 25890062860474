const { randomBytes } = require('crypto')
const { BLAKE2s } = require('@stablelib/blake2s')
const { PUBLIC_KEY_LENGTH, sharedKey: x25519 } = require('@stablelib/x25519')
const {
  NONCE_LENGTH,
  KEY_LENGTH,
  XChaCha20Poly1305
} = require('@stablelib/xchacha20poly1305')
const { tryRetranspose } = require('./x25519-utils')

module.exports = { seal, open, scopen }

/**
 * Encrypts and authenticates a message with x25519-xchacha20-poly1305.
 *
 * @param {x25519.KeyPair} senderKeyPair Curve25519 sender key pair
 * @param {Uint8Array} recipientPublicKey The public key of the message recipient.
 * @param {Uint8Array} msg The plaintext message data.
 * @returns {{envelope: Buffer, partialViewingKey: Buffer}} encrypted enveloped data xnonce:24,ephpubkey:32,ciphertext,tag:16 and optionally a partial viewing key
 */
function seal(senderKeyPair, recipientPublicKey, msg) {
  // get a 192 bit nonce from a csprng
  const xnonce = randomBytes(NONCE_LENGTH)
  const aad = Buffer.concat([
    xnonce,
    // the sender key is always ephemeral or transposed so we send it along in
    // the aad bc these *peers* arent registered
    senderKeyPair.publicKey
  ])

  // doing x25519 - that is do diffie-hellman ontop of curve25519
  const rawSharedKey = x25519(
    senderKeyPair.secretKey,
    recipientPublicKey,
    true /*reject zero output*/
  )
  // rehashing the shared secret for use as key material
  const sharedKey = new BLAKE2s(KEY_LENGTH).update(rawSharedKey).digest()

  // encrypt and authenticate
  const xchacha20poly1305 = new XChaCha20Poly1305(sharedKey)
  const ciphertextPlusTag = xchacha20poly1305.seal(xnonce, msg, aad)

  xchacha20poly1305.clean()
  rawSharedKey.fill(0x00)

  return {
    // xnonce:24,ephpubkey:32,ciphertext,tag:16
    envelope: Buffer.concat([aad, ciphertextPlusTag]),
    partialViewingKey: Buffer.from(sharedKey)
  }
}

/**
 * Decrypts and authenticates a message with x25519-xchacha20-poly1305.
 *
 * @param {x25519.KeyPair} keyPair Curve25519 key pair
 * @param {BigNumber} nonce Signer's shielded account nonce
 * @param {x25519.KeyPair.publicKey[]} peers Peer public keys
 * @param {Uint8Array} msg The raw enrypted msg incl aad, ciphertext, and tag.
 * @returns {null | {plaintext:Buffer, partialViewingKey: Buffer}} {plaintext,partialViewingKey}
 */
function open(keyPair, nonce, peers, msg) {
  const xnonce = msg.subarray(0, NONCE_LENGTH)
  const ephemeralPublicKey = Buffer.from(
    msg.subarray(NONCE_LENGTH, NONCE_LENGTH + PUBLIC_KEY_LENGTH)
  )
  const aad = Buffer.concat([xnonce, ephemeralPublicKey])
  const ciphertextPlusTag = msg.subarray(
    NONCE_LENGTH + PUBLIC_KEY_LENGTH,
    msg.length
  )
  let plaintext
  let _keyPair = keyPair

  // doing x25519 - that is do diffie-hellman ontop of curve25519
  const rawSharedKey = x25519(
    keyPair.secretKey,
    ephemeralPublicKey,
    true /*reject zero output*/
  )
  // rehashing the shared secret for use as key material
  const sharedKey = new BLAKE2s(KEY_LENGTH).update(rawSharedKey).digest()
  // authenticate and decrypt
  const xchacha20poly1305 = new XChaCha20Poly1305(sharedKey)
  plaintext = xchacha20poly1305.open(xnonce, ciphertextPlusTag, aad)
  xchacha20poly1305.clean()
  rawSharedKey.fill(0x00)

  if (plaintext) {
    return {
      plaintext: Buffer.from(plaintext),
      // peer: ephemeralPublicKey,
      partialViewingKey: Buffer.from(sharedKey)
    }
  } else {
    const transposed = tryRetranspose(keyPair, nonce, ephemeralPublicKey)
    if (transposed) {
      _keyPair = transposed
    }
  }

  // find the x25519 counterparty
  for (const publicKey of peers) {
    // doing x25519 - that is do diffie-hellman ontop of curve25519
    const rawSharedKey = x25519(
      _keyPair.secretKey,
      publicKey,
      true /*reject zero output*/
    )
    // rehashing the shared secret for use as key material
    const sharedKey = new BLAKE2s(KEY_LENGTH).update(rawSharedKey).digest()
    // authenticate and decrypt
    const xchacha20poly1305 = new XChaCha20Poly1305(sharedKey)
    plaintext = xchacha20poly1305.open(xnonce, ciphertextPlusTag, aad)
    xchacha20poly1305.clean()
    rawSharedKey.fill(0x00)

    if (plaintext) {
      return {
        plaintext: Buffer.from(plaintext),
        // peer: Buffer.from(publicKey),
        partialViewingKey: Buffer.from(sharedKey)
      }
    }
  }

  return null
}

/**
 * Shortcut open an envelope using a partial viewing key.
 *
 * @param {Buffer} partialViewingKey The partial viewing key
 * @param {Uint8Array} msg The raw enrypted msg incl aad, ciphertext, and tag.
 * @returns {Buffer} plaintext
 */
function scopen(partialViewingKey, msg) {
  const xnonce = msg.subarray(0, NONCE_LENGTH)
  const ephemeralPublicKey = Buffer.from(
    msg.subarray(NONCE_LENGTH, NONCE_LENGTH + PUBLIC_KEY_LENGTH)
  )
  const aad = Buffer.concat([xnonce, ephemeralPublicKey])
  const ciphertextPlusTag = msg.subarray(
    NONCE_LENGTH + PUBLIC_KEY_LENGTH,
    msg.length
  )

  const xchacha20poly1305 = new XChaCha20Poly1305(partialViewingKey)
  const plaintext = xchacha20poly1305.open(xnonce, ciphertextPlusTag, aad)

  xchacha20poly1305.clean()

  if (plaintext) {
    return Buffer.from(plaintext)
  }
  return null
}
