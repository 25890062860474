const { formatUnits, Interface } = require('ethers')
const { BigNumber } = require('@ethersproject/bignumber')
const { bigNumToHex, queryFilterBatched } = require('./utils')
const { PUBLIC_KEY_LENGTH } = require('@stablelib/x25519')
const { NONCE_LENGTH } = require('@stablelib/xchacha20poly1305')
const { scopen } = require('./x25519-xchacha20-poly1305')
const ERC20_ABI = require('./erc20.abi.json')
const WETH_ABI = require('./weth.abi.json')

const ZERO_PUBLIC_KEY = Buffer.alloc(PUBLIC_KEY_LENGTH)

module.exports = (config, poseidonHash) => {
  const registry = require('./registry')(config)
  const Utxo = require('./utxo')(poseidonHash)
  const KeyPair = require('./keypair')(poseidonHash)

  /**
   * Lists historic transaction outputs by given key pair.
   *
   * @param {string|Contract} pool pool contract
   * @param {KeyPair} keypair spender's shielded account keypair
   * @param {string[]} peers Peer shielded addresses
   * @param {string} viewingKey (Compound) viewing key
   * @param {number} from block height offset to start search from
   * @returns {{[key:string]: Utxo[]}} Unspent tx outputs
   */
  async function findUtxosH({
    pool = config.pool,
    keypair,
    peers,
    viewingKey,
    // utxos after from blcok include a type field
    from = config.startBlock
  }) {
    const loadedPeers = config.peers?.map(p => p.shieldedAddress || p) ?? []
    peers = peers ? peers.concat(loadedPeers) : loadedPeers

    const filter = pool.filters.NewCommitment()
    const events = await queryFilterBatched(
      from,
      await config.provider.getBlock().then(b => b.number),
      pool,
      filter
    )
    let utxoHistory = []

    events.forEach(async event => {
      let utxo
      try {
        if (viewingKey) {
          const buf = scopen(
            Buffer.from(viewingKey.replace('0x', ''), 'hex'),
            Buffer.from(event.args.encryptedOutput.replace('0x', ''), 'hex')
          )
          if (!buf) throw Error('scopen(viewingKey,ciphertext) failed')
          const owner = '0x' + buf.subarray(62, 94).toString('hex')
          utxo = new Utxo({
            amount: BigNumber.from('0x' + buf.subarray(0, 31).toString('hex')),
            blinding: BigNumber.from(
              '0x' + buf.subarray(31, 62).toString('hex')
            ),
            keypair: KeyPair.fromString(owner + '0'.repeat(64)), //_keyPair,
            token: '0x' + buf.subarray(94, 114).toString('hex'),
            type: buf[114],
            note: buf.subarray(115, buf.length).toString('utf8') || null,
            index: Number(event.args.index)
          })
        } else {
          const result = Utxo.decrypt(
            keypair,
            peers,
            event.args.encryptedOutput,
            Number(event.args.index)
          )
          utxo = result.utxo
          utxo.partialViewingKey = result.partialViewingKey
        }
      } catch (_) {} // eslint-disable-line no-empty

      if (!utxo) {
        return
      } else {
        if (utxo.amount.toString() === '0') {
          return
        }

        const commitment = bigNumToHex(utxo.getCommitment())
        const event = events.find(e => e.args.commitment === commitment)

        const encryptedBuf = Buffer.from(
          event.args.encryptedOutput.replace('0x', ''),
          'hex'
        )
        const ephemeralPublicKey = Buffer.from(
          encryptedBuf.subarray(NONCE_LENGTH, NONCE_LENGTH + PUBLIC_KEY_LENGTH)
        )
        utxo.ephemeralPublicKey = ephemeralPublicKey.equals(ZERO_PUBLIC_KEY)
          ? null
          : ephemeralPublicKey

        utxoHistory.push({
          amount: utxo.amount,
          type: utxo.type,
          tx: event.transactionHash,
          blockNumber: event.blockNumber,
          keypair: utxo.keypair,
          ephemeralPublicKey: utxo.ephemeralPublicKey,
          note: utxo.note,
          token: utxo.token,
          partialViewingKey: utxo.partialViewingKey
        })
      }
    })

    utxoHistory = utxoHistory.filter(u => u.type !== 0)

    utxoHistory = await Promise.all(
      utxoHistory.map(async u => {
        return {
          ...u,
          date: await config.provider.getBlock(u.blockNumber).then(b => b.date)
        }
      })
    )

    utxoHistory = await Promise.all(
      utxoHistory.map(async u => {
        let receipt
        const poolAddress = await config.pool.getAddress()
        if (u.type === 1 || u.type === 3) {
          receipt = await config.provider.getTransactionReceipt(u.tx)
          let transfer
          const ierc20 = new Interface(ERC20_ABI)
          const iweth = new Interface(WETH_ABI)
          const events = receipt.logs.map(l => {
            try {
              return iweth.parseLog(l)
            } catch (_) {
              return ierc20.parseLog(l)
            }
          })
          // console.log('EVENTS', events)
          receipt.events = events
          if (u.type === 1) {
            transfer = events.find(e => e?.name === 'Transfer') //e => e?.args?.to?.toLowerCase() === poolAddress.toLowerCase())
          } else if (u.type === 3) {
            // await config.provider.send("debug_traceTransaction", [u.tx]).then(r => {
            //   console.log("TRACED>>>>>>>>>>>>>>",r)
            // })
            // maybe there is a wrapped withdrawal
            // transfer = events.find(e => e?.name === "Withdrawal") // this is an WXDAI Withdrawal event
            if (!transfer) {
              //~if not we pick the last bc the first transfer goes to gelato~
              transfer = events
                // .reverse()
                .find(
                  e =>
                    e?.name === 'Transfer' &&
                    (e?.args?.from?.toLowerCase() ||
                      e?.args?.src?.toLowerCase()) === poolAddress.toLowerCase()
                )
            }
          }
          // console.log('TRANSFER', u.type, transfer)
          receipt.events.transfer = transfer
          u.amount = transfer?.args?.value ?? transfer?.args?.wad
        }
        return { ...u, receipt }
      })
    )

    utxoHistory = await Promise.all(
      utxoHistory.map(async u => {
        const shieldedTo = peers.find(p =>
          p.startsWith(bigNumToHex(u.keypair.pubkey))
        )
        if (u.type === 0) {
          u.type = 'bogus'
        } else if (u.type === 1) {
          u.type = 'fund'
        } else if (u.type === 2) {
          if (u.keypair.privkey) {
            u.type = 'inbound transfer'
          } else {
            u.type = 'outbound transfer'
          }
        } else if (u.type === 3) {
          u.type = 'withdraw'
        }

        let from
        let to
        if (u.type === 'inbound transfer') {
          from = u.peer
          to = shieldedTo || keypair.address()
        } else if (u.type === 'withdraw') {
          // console.log('WITHDRAW TRANSFER', u.receipt.events.transfer)
          from = keypair?.address()
          to =
            u.receipt?.events.transfer.args.to ??
            u.receipt?.events.transfer.args.dst
        } else if (u.type === 'outbound transfer') {
          from = keypair?.address()
          to = shieldedTo
        } else if (u.type === 'fund') {
          // console.log('FUND TRANSFER', u.receipt.events.transfer)
          from = from =
            u.receipt?.events.transfer.args.from ??
            u.receipt?.events.transfer.args.src
          to = shieldedTo || keypair?.address()
        }

        // fetch optional .bay namefor to shieldedAddress
        const toBayName = await registry.nameOfShieldedAddress(to)

        return {
          ...u,
          amount: formatUnits(u.amount.toString(), 18),
          from,
          to,
          toBayName,
          note: u.note.length > 1 ? Buffer.from(u.note).toString() : ''
        }
      })
    )

    return utxoHistory
  }

  return findUtxosH
}
