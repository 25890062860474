import { default as ReactModal } from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import { Text, Flex, Box } from 'rebass'
import { Input } from '@rebass/forms'
import { formatEther } from 'ethers'
import Button from './button'
import { dump, register, nameChange, renew, useMyMetaMask } from './redux'
import { fmtDate } from './util'
import { BAY_NAME_PATTERN } from './constants'

export default function RegisterNameModal() {
  const dispatch = useDispatch()
  const { chainName } = useMyMetaMask()
  let {
    name,
    bayNameInput,
    showRegisterNameModal,
    registrationExpiry,
    registrationFee
  } = useSelector(state => state)
  const valid =
    BAY_NAME_PATTERN.test(bayNameInput) || BAY_NAME_PATTERN.test(name)
  registrationExpiry = Number(registrationExpiry)

  return (
    <ReactModal
      isOpen={showRegisterNameModal}
      onRequestClose={() => dispatch(dump({ showRegisterNameModal: false }))}
      contentLabel="register name modal"
      appElement={document.getElementById('root')}
      className="responsive-modal"
    >
      <Flex
        style={{
          justifyContent: 'center',
          marginTop: window.innerHeight < 500 ? '0' : '20vh'
        }}
      >
        <Box
          sx={{
            background: '#fff',
            border: '0.1875em solid #000',
            boxShadow: '0 0.625em',
            padding: '0.625em',
            wordWrap: 'break-word',
            textAlign: 'center',
            fontSize: ['1em', '1.25em']
          }}
        >
          <Text sx={{ fontWeight: 'bold', marginBottom: '0.625em' }}>
            ®️ {name ? 'Renew' : 'Register'}
          </Text>
          <Text
            sx={{
              textAlign: 'center',
              // fontSize: [16, 18, 20],
              marginBottom: '0.625em'
            }}
          >
            {name ? 'Renew your' : 'Register a'} <code>.bay</code> name
            {name ? ' registration' : ' as an alias for your shielded address'}.
          </Text>
          <Input
            type="text"
            id="bay-name-input"
            placeholder="name.bay"
            onChange={e => dispatch(nameChange(e.target.value))}
            value={bayNameInput || name || ''}
            bg="#fff"
            sx={{
              textAlign: 'center',
              // fontSize: [16, 18, 20],
              marginBottom: '0.3125em'
            }}
          />

          <Box sx={{ fontSize: [10, 12] }}>
            {valid && registrationExpiry ? (
              <Text sx={{ marginBottom: '0.625em' }}>
                Expiry: {fmtDate(new Date(registrationExpiry * 1000))}
              </Text>
            ) : null}
          </Box>
          <Flex
            sx={{ marginBottom: '0.3125em', justifyContent: 'space-between' }}
          >
            <span style={{ fontWeight: 'bold', marginRight: '0.3125em' }}>
              TOTAL
            </span>
            <span>
              {formatEther(registrationFee || 0)}{' '}
              {chainName === 'sepolia' ? 'CBAY' : 'BAY'}
            </span>
          </Flex>

          <Button
            disabled={!valid}
            aria-label={name ? 'Renew' : 'Register'}
            style={{
              marginTop: '0.3125em',
              cursor: valid ? 'pointer' : 'not-allowed'
            }}
            onClick={() => {
              const action = name ? renew : register
              dispatch(action())
            }}
          >
            {name ? 'Renew' : 'Register'}
          </Button>
        </Box>
      </Flex>
    </ReactModal>
  )
}
