const { BigNumber } = require('@ethersproject/bignumber')

module.exports = async function () {
  const { buildPoseidon } = await import('circomlibjs')
  const poseidon = await buildPoseidon()
  function poseidonHash(items) {
    return BigNumber.from(poseidon.F.toString(poseidon(items), 10))
  }
  function poseidonHash2(a, b) {
    return poseidonHash([a, b])
  }
  function poseidonHash5(a, b, c, d, e) {
    return poseidonHash([a, b, c, d, e])
  }
  return { poseidonHash, poseidonHash2, poseidonHash5, poseidon }
}
