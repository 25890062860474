const { Contract, JsonRpcProvider, getDefaultProvider } = require('ethers')
const {
  ERC20_ABI,
  POOL_ABI,
  REGISTRY_ABI,
  resolveShieldedAddress,
  resolveNativeAddress,
  bigNumToHex,
  bigNumToBuf,
  mapTransactArgs,
  sumAmounts,
  onprogress,
  progress
} = require('./utils')
const poolAbi = require('./pool.abi.json')
const registryAbi = require('./registry.abi.json')
const cbayAbi = require('./cbay.abi.json')
const chain = require('./chain')

let omnipool
let registry
let core
let config
let findUtxos
let findUtxosUpTo
let findUtxosH
let safe

module.exports = init

async function init(opts, _opts) {
  if (/^chiado$/i.test(opts)) {
    config = { ...chain.chiado, ..._opts }
  } else if (/^gnosis$/i.test(opts)) {
    config = { ...chain.gnosis, ..._opts }
  } else if (/^sepolia$/i.test(opts)) {
    config = { ...chain.sepolia, ..._opts }
  } else {
    config = {
      provider: getDefaultProvider(),
      zkAssetsBaseUrl: 'artifacts/circuits',
      merkleTreeHeight: 5,
      ...opts
    }
  }

  const { poseidonHash, poseidonHash2, poseidonHash5 } =
    await require('./poseidon')()

  omnipool = require('./omnipool')(
    config,
    poseidonHash,
    poseidonHash2,
    poseidonHash5
  )
  registry = require('./registry')(config)
  core = require('./core')(config, poseidonHash, poseidonHash2, poseidonHash5)
  const findUtxosModule = require('./find-utxos')(config, poseidonHash)
  findUtxos = findUtxosModule.findUtxos
  findUtxosUpTo = findUtxosModule.findUtxosUpTo
  findUtxosH = require('./find-utxosh')(config, poseidonHash)
  safe = require('./safe')(config, poseidonHash, poseidonHash2, poseidonHash5)
  const gelato = require('./gelato')(
    config,
    poseidonHash,
    poseidonHash2,
    poseidonHash5
  )

  if (!config.provider) {
    config.provider = getDefaultProvider()
  }

  if (typeof config.provider === 'string') {
    config.provider = new JsonRpcProvider(config.provider)
  }
  if (typeof config.pool === 'string') {
    config.pool = new Contract(config.pool, poolAbi, {
      provider: config.provider
    })
  }
  if (typeof config.registry === 'string') {
    config.registry = new Contract(config.registry, registryAbi, {
      provider: config.provider
    })
  }
  if (typeof config.cbay === 'string') {
    config.cbay = new Contract(config.cbay, cbayAbi, {
      provider: config.provider
    })
  }

  const KeyPair = require('./keypair')(poseidonHash)
  const Utxo = require('./utxo')(poseidonHash)

  return {
    omnipool: {
      ...omnipool,
      fundFromSafe: omnipool.proposeApproveAndFund //safe.proposeApproveAndFund
    },
    registry,
    core,
    utils: {
      ERC20_ABI,
      POOL_ABI,
      REGISTRY_ABI,
      findUtxos,
      findUtxosUpTo,
      findUtxosH,
      sumAmounts,
      mapTransactArgs,
      resolveShieldedAddress,
      resolveNativeAddress,
      poseidonHash,
      poseidonHash2,
      bigNumToHex,
      bigNumToBuf,
      gelatoRelayFeeEstimate: gelato.relayFeeEstimate,
      onprogress,
      progress,
      safeStxHash: safe.msgHash,
      safeProposeStxHash: safe.proposeStxHash,
      // safeProposeApproval: safe.proposeApproval,
      safesOf: safe.safesOf,
      _reduceAddModIf: safe._reduceAddModIf // exposed only 4 testing
    },
    config,
    KeyPair,
    Utxo,
    Buffer
  }
}
