const { BigNumber } = require('@ethersproject/bignumber')
const { scalarMult, generateKeyPairFromSeed } = require('@stablelib/x25519')

module.exports = { transpose, tryRetranspose }

/**
 * Transposes a x25519 key pair by given scalar plus two.
 * Just performs a scalar multiplication on curve 25519.
 *
 * @param {x25519.KeyPair} keyPair x25519 key pair
 * @param {BigNumber} scalar Transposition factor
 * @returns {x25519.KeyPair} Transposed key pair
 */
function transpose(keyPair, scalar) {
  const scalarBuf = Buffer.alloc(32)
  scalarBuf.set(
    Buffer.from(
      BigNumber.from(scalar + 2) // offset b/c 0 and 1 are noops 4 scalarmult()
        .toHexString()
        .replace('0x', ''),
      'hex'
    )
  )
  const product = scalarMult(keyPair.secretKey, scalarBuf)
  const _keyPair = generateKeyPairFromSeed(product)
  _keyPair.publicKey = Buffer.from(_keyPair.publicKey)
  _keyPair.secretKey = Buffer.from(_keyPair.secretKey)
  return _keyPair
}

/**
 * Attempts to recreate a transposed keypair.
 *
 * @param {x25519.KeyPair} keyPair x25519 key pair
 * @param {BigNumber} scalar Transposition factor
 * @returns {x25519.KeyPair} Transposed key pair
 */
function tryRetranspose(keyPair, scalar, expectedPublickey) {
  // transposition nonce was offset by +2
  scalar = BigNumber.from(scalar + 2)
  let i = BigNumber.from(0)
  let transposed
  while (i.lte(scalar)) {
    transposed = transpose(keyPair, i)
    if (transposed.publicKey.equals(expectedPublickey)) {
      return transposed
    }
    i = i.add(1)
  }
}
