import { useMyMetaMask } from './redux'
import { shorten } from './util'

export function TxAnchor({ hash }) {
  const { chainName } = useMyMetaMask()
  if (!hash) return null
  const href =
    chainName === 'gnosis'
      ? `https://gnosisscan.io/tx/${hash}`
      : `https://sepolia.etherscan.io/tx/${hash}`
  return (
    <a
      href={href}
      target="_blank"
      style={{
        margin: 0,
        cursor: 'pointer'
      }}
    >
      {shorten(hash)}
    </a>
  )
}

export function Anchor({ href, text }) {
  if (!href || !text) return null
  return (
    <a
      href={href}
      target="_blank"
      style={{
        margin: 0,
        cursor: 'pointer'
      }}
    >
      {text}
    </a>
  )
}
