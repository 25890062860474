import { Box, Flex } from 'rebass'
import { Input } from '@rebass/forms'
import Button from './button'
import { useDispatch, useSelector } from 'react-redux'
import { dump, maxout } from './redux'
import { prettierBalance } from './util'

export default function ShieldedTokenInput(props) {
  const { selectedToken, shieldedTokenAmount } = useSelector(state => state)
  const dispatch = useDispatch()
  return (
    <Flex
      sx={{ marginBottom: '0.625em', position: 'relative' }}
      id="token-input"
    >
      <Input
        {...{ ...props }}
        sx={{
          fontWeight: 'normal',
          fontSize: [16, 18, 20],
          fontFamily: 'Open Sans',
          textAlign: 'right',
          ...props.sx,
          ...props.style
        }}
        type="text"
        id="token-input-amount"
        placeholder={'0.00'}
        onChange={e => dispatch(dump({ shieldedTokenAmount: e.target.value }))}
        onPaste={e => dispatch(dump({ shieldedTokenAmount: e.target.value }))}
        value={prettierBalance(shieldedTokenAmount) || ''}
      >
        {props.text || props.children}
      </Input>
      <Button
        aria-label="Token-input"
        sx={{
          minWidth: 'initial',
          width: 'min-content',
          align: 'center',
          paddingTop: 0,
          paddingRight: '0.3125em',
          paddingBottom: 0,
          paddingLeft: '0.3125em',
          fontWeight: 'bold'
        }}
        onClick={() => dispatch(dump({ showTokenModal: true }))}
      >
        {selectedToken}
      </Button>
      <Box
        sx={{
          display: 'inline-flex',
          position: 'absolute',
          background: '#fff',
          top: ['1em', '1.15em', '1.25em'],
          marginLeft: '0.3125em',
          padding: '0 0.3125em',
          boxShadow: '0.3125em 0 0 rgba(255,255,255,.5)',
          fontSize: '0.75em',
          fontWeight: 'bold',
          cursor: 'pointer'
        }}
        onClick={() => dispatch(maxout(true))}
      >
        MAX
      </Box>
    </Flex>
  )
}
