const ethers = require('ethers')
const { BigNumber } = require('@ethersproject/bignumber')
const { default: SafeApiKit } = require('@safe-global/api-kit')
const { default: Safe, EthersAdapter } = require('@safe-global/protocol-kit')
const { sumAmounts, mapTransactArgs, progress } = require('./utils')
const ERC20_ABI = require('./erc20.abi.json')

module.exports = (config, poseidonHash, poseidonHash2, poseidonHash5) => {
  const core = require('./core')(
    config,
    poseidonHash,
    poseidonHash2,
    poseidonHash5
  )
  const findUtxosUpTo = require('./find-utxos')(
    config,
    poseidonHash
  ).findUtxosUpTo
  const gelato = require('./gelato')(
    config,
    poseidonHash,
    poseidonHash2,
    poseidonHash5
  )
  const safeStx = require('./safe')(
    config,
    poseidonHash,
    poseidonHash2,
    poseidonHash5
  )
  const Utxo = require('./utxo')(poseidonHash)
  const KeyPair = require('./keypair')(poseidonHash)

  /**
   * Deposits given amount to shielded recipient.
   * The deposit value is public on the blockchain.
   *
   * @param {Signer} signer depositor
   * @param {number|string} amount deposit amount
   * @param {string} token ERC20 token address
   * @param {string} recipient shielded address
   * @param {undefined|string} note tx note
   * @returns {{ receipt, keypair }} tx receipt and maybe a derived shielded account
   */
  async function fund({ signer, amount, token, recipient, note }) {
    progress('Initiating funding')
    const depositUtxo = new Utxo({
      keypair: KeyPair.fromString(recipient),
      amount: BigNumber.from(ethers.parseEther(String(amount))),
      token,
      note,
      type: 1
    })
    const { receipt, partialViewingKey } = await core.transact({
      pool: config.pool.connect(signer),
      outputs: [depositUtxo],
      token
    })
    progress('')
    return { receipt, partialViewingKey }
  }

  /**
   * Transfers given amount within the pool.
   *
   * @param {Contract} pool Omnipool
   * @param {Signer} signer sender
   * @param {KeyPair} keypair sender's shielded account keypair
   * @param {string[]} peers Peer shielded addresses
   * @param {number|string} amount transfer amount
   * @param {string} token ERC20 token address
   * @param {string} recipient shielded address
   * @param {boolean} useGelatoRelay use gelato relay?
   * @param {undefined|string} note tx note
   * @returns {receipt} tx receipt
   */
  async function transfer({
    signer,
    keypair,
    amount: _amount,
    token,
    recipient,
    note,
    peers = config.peers,
    pool = config.pool,
    useGelatoRelay,
    gelatoFee
  }) {
    if (keypair.address() === recipient) {
      throw Error('cannot transfer to self')
    }
    progress('Initiating transfer')
    await keypair.fetchNonce(pool)
    //TODO adjust 4 stablecoin 6 decimals by reading $token.decimals() off blokchain
    const amount = BigNumber.from(ethers.parseEther(String(_amount)))
    token = token.toLowerCase()
    const utxos = await findUtxosUpTo({
      pool,
      keypair,
      peers,
      token,
      amount
    })
    const spend = sumAmounts(utxos)

    //TODO if recipient is safe set its adrs
    const plusUtxoSafe = await safeStx.shieldedToSafe(recipient)
    const minusUtxoSafe = await safeStx.shieldedToSafe(keypair.address())
    console.log('✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞ plusUtxoSafe', plusUtxoSafe)
    console.log('✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞ minusUtxoSafe', minusUtxoSafe)
    const plusUtxo = new Utxo({
      amount,
      token,
      keypair: KeyPair.fromString(recipient),
      note,
      type: 2,
      safe: plusUtxoSafe
    })
    const minusUtxo = new Utxo({
      amount: spend.sub(amount),
      token,
      keypair,
      encryptEphemeral: true,
      type: 0,
      safe: minusUtxoSafe
    })

    if (useGelatoRelay) {
      // console.log(
      //   'amount\t' + amount.toString() + '\n' + 'gelato\t' + gelatoFee
      // )
      const minusUtxo = new Utxo({
        amount: spend.sub(amount).sub(gelatoFee),
        token,
        keypair,
        encryptEphemeral: true,
        type: 0,
        safe: minusUtxoSafe
      })
      return gelato.relay({
        pool,
        inputs: utxos,
        outputs: [plusUtxo, minusUtxo],
        token,
        fee: gelatoFee,
        noteLength: note.length
      })
    } else {
      const { receipt, partialViewingKey } = await core.transact({
        pool: config.pool.connect(signer),
        inputs: utxos,
        outputs: [plusUtxo, minusUtxo],
        token
      })
      progress('')
      return { receipt, partialViewingKey }
    }
  }

  /**
   * Withdraws given amount from the pool.
   *
   * @param {Contract} pool Omnipool
   * @param {Signer} signer sender
   * @param {KeyPair} keypair withdrawer's shielded account keypair
   * @param {string[]} peers Peer shielded addresses
   * @param {number|string} amount withdraw amount
   * @param {string} token ERC20 token address
   * @param {string} recipient gnosis address
   * @param {boolean} useGelatoRelay use gelato relay?
   * @param {boolean} unwrap Unwrap WXDAI/WETH?
   * @returns {receipt} tx receipt
   */
  async function withdraw({
    signer,
    keypair,
    amount: _amount,
    token,
    recipient,
    unwrap,
    peers = config.peers,
    pool = config.pool,
    useGelatoRelay,
    gelatoFee
  }) {
    progress('Initiating withdrawal')
    await keypair.fetchNonce(pool)
    //TODO adjust 4 stablecoin 6 decimals by reading $token.decimals() off blokchain
    const amount = BigNumber.from(ethers.parseEther(String(_amount)))
    const utxos = await findUtxosUpTo({
      pool,
      keypair,
      peers,
      token,
      amount
    })
    const spend = sumAmounts(utxos)

    const minusUtxoSafe = await safeStx.shieldedToSafe(keypair.address())
    console.log(
      '✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞ withdraw minusUtxoSafe',
      minusUtxoSafe
    )

    const minusUtxo = new Utxo({
      amount: spend.sub(amount),
      token,
      keypair,
      encryptEphemeral: true,
      type: 3,
      safe: minusUtxoSafe
    })
    if (useGelatoRelay) {
      const minusUtxo = new Utxo({
        amount: spend.sub(amount).sub(gelatoFee),
        token,
        keypair,
        encryptEphemeral: true,
        type: 3,
        safe: minusUtxoSafe
      })
      return gelato.relay({
        pool,
        inputs: utxos,
        outputs: [minusUtxo],
        recipient,
        token,
        fee: gelatoFee,
        unwrap,
        noteLength: 0
      })
    } else {
      const { receipt, partialViewingKey } = await core.transact({
        pool: config.pool.connect(signer),
        inputs: utxos,
        outputs: [minusUtxo],
        token,
        recipient,
        unwrap
      })
      progress('')
      return { receipt, partialViewingKey }
    }
  }

  /**
   * Deposits given amount to shielded recipient from a Safe.
   * Includes an allowance approval as part of a MultiSend delegatecall.
   * The deposit value is public on the blockchain.
   *
   * @param {Signer} signer depositor
   * @param {string} safeAddress Safe to fund from
   * @param {number|string} amount deposit amount
   * @param {string} token ERC20 token address
   * @param {string} recipient shielded address
   * @param {undefined|string} note tx note
   * @returns {{ partialViewingKey, safeTxHash }} Safe tx metadata
   */
  async function proposeApproveAndFund({
    signer,
    safeAddress: _safeAddress,
    amount: _amount,
    token,
    recipient,
    note
  }) {
    progress('Initiating funding from Safe')
    const safeAddress = ethers.getAddress(_safeAddress)
    const poolAddress = await config.pool.getAddress()
    const amount =
      typeof _amount === 'string'
        ? BigNumber.from(ethers.parseEther(_amount))
        : BigNumber.from(_amount) //FIXME decimals

    const depositUtxo = new Utxo({
      keypair: KeyPair.fromString(recipient),
      amount,
      token,
      note,
      type: 1
    })

    const { args, extData, partialViewingKey } = await core.prepareTransact({
      outputs: [depositUtxo],
      token
    })

    const safeSigner = await Safe.create({
      ethAdapter: new EthersAdapter({ ethers, signerOrProvider: signer }),
      safeAddress
    })

    const approvalTxData = new ethers.Interface(ERC20_ABI).encodeFunctionData(
      'approve',
      // eslint-disable-next-line no-undef
      [poolAddress, BigInt(amount.toHexString())]
    )
    const fundTxData = config.pool.interface.encodeFunctionData(
      'transact',
      mapTransactArgs([args, extData])
    )
    const safeTx = await safeSigner.createTransaction({
      transactions: [
        {
          to: token,
          data: approvalTxData,
          operation: 0, // call
          value: 0
        },
        {
          to: poolAddress,
          data: fundTxData,
          operation: 0, // call
          value: 0
        }
      ]
    })

    const chainId = await signer.provider
      .getNetwork()
      .then(({ chainId }) => chainId)
    const apiKit = new SafeApiKit({ chainId })
    const safeTxHash = await safeSigner.getTransactionHash(safeTx)
    const senderSignature = await safeSigner.signHash(safeTxHash)
    await apiKit.proposeTransaction({
      safeAddress,
      safeTransactionData: safeTx.data,
      safeTxHash,
      senderAddress: ethers.getAddress(signer.address),
      senderSignature: senderSignature.data
    })
    progress('')
    return { partialViewingKey, safeTxHash }
  }

  return { fund, transfer, withdraw, proposeApproveAndFund }
}
