const ethers = require('ethers')
const { BigNumber } = require('@ethersproject/bignumber')
const { default: SafeApiKit } = require('@safe-global/api-kit')
const { default: Safe, EthersAdapter } = require('@safe-global/protocol-kit')
// const ERC20_ABI = require('./erc20.abi.json')
const { FIELD_SIZE } = require('./utils')

module.exports = (config, poseidonHash, poseidonHash2, poseidonHash5) => {
  // const core = require('./core')(
  //   config,
  //   poseidonHash,
  //   poseidonHash2,
  //   poseidonHash5
  // )
  // const Utxo = require('./utxo')(poseidonHash)
  // const KeyPair = require('./keypair')(poseidonHash)
  const registry = require('./registry')(config)
  const safeApi = new SafeApiKit({ chainId: config.chainId })

  async function fetchNoirProof(safe, msgHash) {
    console.log(
      '✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞ fetchNoirProof config.chainId',
      config.chainId
    )
    const url = `${config.noirServer}/proof`
    const params = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        chain_id: config.chainId,
        safe_address: safe,
        message_hash: msgHash
      })
    }
    return fetch(url, params).then(async res => {
      const result = await res.json()
      if (res.status !== 200) {
        throw Error(result.error)
      }
      return result
    })
  }

  // function _reduceAddModIf(values, selectors) {
  //   let accu = BigNumber.from(0)
  //   for (let i = 0; i < values.length; i++) {
  //     if (!BigNumber.from(selectors[i]).isZero()) {
  //       accu = accu.xor(BigNumber.from(values[i]))
  //     }
  //   }
  //   return accu
  // }

  //NOTE actuly addmod
  function _reduceAddModIf(values, selectors) {
    let accu = BigNumber.from(0)
    for (let i = 0; i < values.length; i++) {
      if (!BigNumber.from(selectors[i]).isZero()) {
        // accu = accu.xor(BigNumber.from(values[i]))
        accu = accu.add(BigNumber.from(values[i])).mod(FIELD_SIZE)
      }
    }
    return accu
  }

  //FIXME handle shielded+native recipient and tx notes!!!!
  function msgHash({ token, safe, value, nullifiers, amounts }) {
    const accuNullifier = _reduceAddModIf(nullifiers, amounts)
    return poseidonHash5(
      BigNumber.from(config.chainId),
      BigNumber.from(safe),
      BigNumber.from(token),
      BigNumber.from(value),
      accuNullifier
    ).toHexString()
  }

  async function proposeStxHash(signer, safeAddress, msgHash) {
    const _safeAddress = ethers.getAddress(safeAddress)
    const ethAdapter = new EthersAdapter({ ethers, signerOrProvider: signer })
    const safeSigner = await Safe.create({
      ethAdapter,
      safeAddress: _safeAddress
    })
    const data = new ethers.Contract(config.signMsgHashLib, [
      'function signMessageHash(bytes32 msgHash) external'
    ]).interface.encodeFunctionData('signMessageHash', [msgHash])
    const safeTxData = {
      to: config.signMsgHashLib,
      data,
      operation: 1, // delegatecall
      value: 0
    }
    const safeTx = await safeSigner.createTransaction({
      transactions: [safeTxData]
    })
    const chainId = await signer.provider
      .getNetwork()
      .then(({ chainId }) => chainId)
    const apiKit = new SafeApiKit({ chainId })
    // Deterministic hash based on tx params
    const safeTxHash = await safeSigner.getTransactionHash(safeTx)
    // Sign Safe tx thereby adding first confirmation
    const senderSignature = await safeSigner.signHash(safeTxHash)
    await apiKit.proposeTransaction({
      safeAddress: _safeAddress,
      safeTransactionData: safeTx.data,
      safeTxHash,
      senderAddress: ethers.getAddress(signer.address),
      senderSignature: senderSignature.data
    })
    return { safeTxHash }
  }

  // async function proposeApproval(signer, safeAddress, tokenAddress, amount) {
  //   const _safeAddress = ethers.getAddress(safeAddress)
  //   const ethAdapter = new EthersAdapter({ ethers, signerOrProvider: signer })
  //   const safeSigner = await Safe.create({
  //     ethAdapter,
  //     safeAddress: _safeAddress
  //   })
  //   const data = new ethers.Interface(ERC20_ABI).encodeFunctionData('approve', [
  //     await config.pool.getAddress(),
  //     amount
  //   ])
  //   const safeTxData = {
  //     to: tokenAddress,
  //     data,
  //     operation: 1, // delegatecall
  //     value: 0
  //   }
  //   const safeTx = await safeSigner.createTransaction({
  //     transactions: [safeTxData]
  //   })
  //   const chainId = await signer.provider
  //     .getNetwork()
  //     .then(({ chainId }) => chainId)
  //   const apiKit = new SafeApiKit({ chainId })
  //   // Deterministic hash based on tx params
  //   const safeTxHash = await safeSigner.getTransactionHash(safeTx)
  //   // Sign Safe tx thereby adding first confirmation
  //   const senderSignature = await safeSigner.signHash(safeTxHash)
  //   await apiKit.proposeTransaction({
  //     safeAddress: _safeAddress,
  //     safeTransactionData: safeTx.data,
  //     safeTxHash,
  //     senderAddress: ethers.getAddress(signer.address),
  //     senderSignature: senderSignature.data
  //   })
  //   return { safeTxHash }
  // }

  // /**
  //  * Deposits given amount to shielded recipient from a Safe.
  //  * The deposit value is public on the blockchain.
  //  *
  //  * @param {Signer} signer depositor
  //  * @param {string} safeAddress Safe to fund from
  //  * @param {number|string} amount deposit amount
  //  * @param {string} token ERC20 token address
  //  * @param {string} recipient shielded address
  //  * @param {undefined|string} note tx note
  //  * @returns {{ partialViewingKey, safeTxHash }} Safe tx metadata
  //  */
  // async function fund({ signer, safeAddress, amount, token, recipient, note }) {
  //   progress('Initiating funding from Safe')
  //   const depositUtxo = new Utxo({
  //     keypair: KeyPair.fromString(recipient),
  //     amount: BigNumber.from(ethers.parseEther(String(amount))), //FIXME decimals
  //     token,
  //     note,
  //     type: 1
  //   })
  //   const { args, extData, partialViewingKey } = await core.prepareTransact({
  //     outputs: [depositUtxo]
  //   })
  //   const _safeAddress = ethers.getAddress(safeAddress)
  //   const safeSigner = await Safe.create({
  //     ethAdapter: new EthersAdapter({ ethers, signerOrProvider: signer }),
  //     safeAddress: _safeAddress
  //   })
  //   const safeTxData = {
  //     to: await config.pool.getAddress(),
  //     data: config.pool.interface.encodeFunctionData(
  //       'transact',
  //       mapTransactArgs([args, extData])
  //     ),
  //     operation: 1, // delegatecall
  //     value: 0
  //   }
  //   const safeTx = await safeSigner.createTransaction({
  //     transactions: [safeTxData]
  //   })
  //   const chainId = await signer.provider
  //     .getNetwork()
  //     .then(({ chainId }) => chainId)
  //   const apiKit = new SafeApiKit({ chainId })
  //   const safeTxHash = await safeSigner.getTransactionHash(safeTx)
  //   const senderSignature = await safeSigner.signHash(safeTxHash)
  //   await apiKit.proposeTransaction({
  //     safeAddress: _safeAddress,
  //     safeTransactionData: safeTx.data,
  //     safeTxHash,
  //     senderAddress: ethers.getAddress(signer.address),
  //     senderSignature: senderSignature.data
  //   })
  //   progress('')
  //   return { partialViewingKey, safeTxHash }
  // }

  // /**
  //  * Deposits given amount to shielded recipient from a Safe.
  //  * Includes an allowance approval as part of a MultiSend delegatecall.
  //  * The deposit value is public on the blockchain.
  //  *
  //  * @param {Signer} signer depositor
  //  * @param {string} safeAddress Safe to fund from
  //  * @param {number|string} amount deposit amount
  //  * @param {string} token ERC20 token address
  //  * @param {string} recipient shielded address
  //  * @param {undefined|string} note tx note
  //  * @returns {{ partialViewingKey, safeTxHash }} Safe tx metadata
  //  */
  // async function proposeApproveAndFund({
  //   signer,
  //   safeAddress: _safeAddress,
  //   amount: _amount,
  //   token,
  //   recipient,
  //   note
  // }) {
  //   progress('Initiating funding from Safe')
  //   const safeAddress = ethers.getAddress(_safeAddress)
  //   const poolAddress = await config.pool.getAddress()
  //   const amount =
  //     typeof _amount === 'string'
  //       ? BigNumber.from(ethers.parseEther(_amount))
  //       : BigNumber.from(_amount) //FIXME decimals
  //   const depositUtxo = new Utxo({
  //     keypair: KeyPair.fromString(recipient),
  //     amount,
  //     token,
  //     note,
  //     type: 1
  //   })
  //   const { args, extData, partialViewingKey } = await core.prepareTransact({
  //     outputs: [depositUtxo]
  //   })
  //   const safeSigner = await Safe.create({
  //     ethAdapter: new EthersAdapter({ ethers, signerOrProvider: signer }),
  //     safeAddress
  //   })
  //   const approvalTxData = new ethers.Interface(ERC20_ABI).encodeFunctionData(
  //     'approve',
  //     [poolAddress, amount]
  //   )
  //   const fundTxData = config.pool.interface.encodeFunctionData(
  //     'transact',
  //     mapTransactArgs([args, extData])
  //   )
  //   //WIP WIP
  //   // https://github.com/safe-global/safe-smart-account/blob/8340a4e6e898755aaca8b285f164c20e41891691/contracts/libraries/MultiSend.sol#L20
  //   const multiSendData = new ethers.AbiCoder().encode(
  //     [
  //       'tuple(bytes1 operation, bytes20 to, uint256 value, uint256 data_len, bytes data,  bytes1 operation, bytes20 to, uint256 value, uint256 data_len, bytes data)'
  //     ],
  //     [
  //       [
  //         bigNumToHex(1, 1),
  //         bigNumToHex(token, 20),
  //         bigNumToHex(0, 32),
  //         bigNumToHex((approvalTxData.length - 2) / 2, 32),
  //         approvalTxData,
  //         bigNumToHex(1, 1),
  //         bigNumToHex(poolAddress, 20),
  //         bigNumToHex(0, 32),
  //         bigNumToHex((fundTxData.length - 2) / 2, 32),
  //         fundTxData
  //       ]
  //     ]
  //   )
  //   const multiSendTxData = new ethers.Interface([
  //     'function multiSend(bytes memory transactions) public'
  //   ]).encodeFunctionData('multiSend', [multiSendData])
  //   const safeTxData = {
  //     to: config.multiSendLib,
  //     data: multiSendTxData,
  //     operation: 1, // delegatecall
  //     value: 0
  //   }
  //   const safeTx = await safeSigner.createTransaction({
  //     transactions: [safeTxData]
  //   })
  //   const chainId = await signer.provider
  //     .getNetwork()
  //     .then(({ chainId }) => chainId)
  //   const apiKit = new SafeApiKit({ chainId })
  //   const safeTxHash = await safeSigner.getTransactionHash(safeTx)
  //   const senderSignature = await safeSigner.signHash(safeTxHash)
  //   await apiKit.proposeTransaction({
  //     safeAddress,
  //     safeTransactionData: safeTx.data,
  //     safeTxHash,
  //     senderAddress: ethers.getAddress(signer.address),
  //     senderSignature: senderSignature.data
  //   })
  //   progress('')
  //   return { partialViewingKey, safeTxHash }
  // }

  async function safesOf(eoa) {
    const { safes } = await safeApi.getSafesByOwner(ethers.getAddress(eoa))
    return safes.map(safeAddress => ({
      safeAvatar: `https://robohash.org/${safeAddress}.png`,
      safeAddress: safeAddress.toLowerCase(),
      eoa
    }))
  }

  async function shieldedToSafe(shieldedAddress) {
    const senderNativeAddress = await registry.nativeAddressOf(shieldedAddress)
    console.log(
      '✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞ shieldedToSafe::senderNativeAddress',
      shieldedAddress,
      '=>',
      senderNativeAddress
    )
    if (!senderNativeAddress) {
      return null
    }
    try {
      console.log('✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞ inside try b4 getThreshold()')
      console.log(
        '✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞ inside try senderNativeAddress',
        senderNativeAddress
      )
      // duckcheck if sender is a Safe
      await new ethers.Contract(
        senderNativeAddress,
        ['function getThreshold() public view returns (uint256)'],
        {
          provider: config.provider
        }
      ).getThreshold()
      console.log('✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞ inside try after getThreshold()')
      return senderNativeAddress
    } catch (err) {
      console.error(err)
      return null
    }
  }

  return {
    msgHash,
    fetchNoirProof,
    safesOf,
    shieldedToSafe,
    proposeStxHash,
    _reduceAddModIf // exposed only 4 testing
    // /*proposeApproval, fund,*/ proposeApproveAndFund
  }
}
