export const NATIVE_ADDRESS_PATTERN = /^0x[a-fA-F0-9]{40}$/
export const SHIELDED_ADDRESS_PATTERN = /^0x[a-fA-F0-9]{128}$/
export const BAY_NAME_PATTERN = /^.{3,}\.bay$/i
export const CHAIN_NAME = {
  10200: 'Chiado',
  100: 'Gnosis',
  5: 'Goerli',
  11155111: 'Sepolia'
}
export const FAUCET_DRIP = 5e18
export const TOKENS = [
  //TODO sDai
  // {
  //   symbol: 'WXDAI',
  //   image: '/img/wxdai-logo.png',
  //   decimals: 18,
  //   address: {
  //     chiado: '0x18c8a7ec7897177E4529065a7E7B0878358B3BfF',
  //     gnosis: '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d'
  //   }
  // },
  // {
  //   symbol: 'GNO',
  //   image: '/img/gno-logo.webp',
  //   decimals: 18,
  //   address: {
  //     chiado: '0x19C653Da7c37c66208fbfbE8908A5051B57b4C70',
  //     gnosis: '0x9C58BAcC331c9aa871AFD802DB6379a98e80CEdb'
  //   }
  // },
  {
    symbol: 'USDC',
    image: '/img/usdc-logo.webp',
    decimals: 6,
    address: {
      chiado: '',
      gnosis: '0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83',
      sepolia: '0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238'
    }
  },
  // {
  //   symbol: 'USDT',
  //   image: '/img/usdt-logo.webp',
  //   decimals: 6,
  //   address: {
  //     chiado: '',
  //     gnosis: '0x4ECaBa5870353805a9F068101A40E0f32ed605C6',
  //     sepolia: 'TODO'
  //   }
  // },
  {
    symbol: 'WETH',
    image: '/img/weth-logo.webp',
    decimals: 18,
    address: {
      chiado: '',
      gnosis: '0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1',
      sepolia: '0x7b79995e5f793A07Bc00c21412e50Ecae098E7f9'
    }
  }
]
