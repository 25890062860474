const { getAddress } = require('ethers')
const { GelatoRelay } = require('@gelatonetwork/relay-sdk')
const { mapTransactArgs, progress } = require('./utils')

module.exports = (config, poseidonHash, poseidonHash2, poseidonHash5) => {
  const { prepareTransact } = require('./core')(
    config,
    poseidonHash,
    poseidonHash2,
    poseidonHash5
  )
  const gelato = new GelatoRelay()

  async function relay({
    pool,
    inputs,
    outputs,
    recipient,
    token,
    fee,
    unwrap,
    nonce,
    nonceKey,
    noteLength
  }) {
    progress('Preparing Gelato Relay transaction')
    token = getAddress(token)
    const { args, extData, partialViewingKey } = await prepareTransact({
      pool,
      inputs,
      outputs,
      fee,
      recipient,
      token,
      relayer: 0,
      unwrap,
      nonce,
      nonceKey
    })
    //FIXME gelato fee recheck uses dirty window.alert()
    progress('Rechecking Gelato Relay fee')
    const gelatoFee = await relayFeeEstimate(token, config.chainId, noteLength)
    // if (gelatoFee.gt(fee)) {
    //   console.log(">>>>>>>>>>>> new gelatoFee, old fee", gelatoFee.toString(), fee.toString())
    //   alert("This transaction might fail due to the Gelato Relay fee spiking just now. In case of failure please try again.")
    // }
    progress('Dispatching transaction via Gelato Relay')
    const res = await gelato.callWithSyncFee({
      chainId: Number(config.chainId),
      target: await pool.getAddress(),
      data: pool.interface.encodeFunctionData(
        'transactGelato',
        mapTransactArgs([args, extData])
      ),
      feeToken: token,
      isRelayContext: true
    })
    progress('Awaiting transaction confirmation')
    res.partialViewingKey = partialViewingKey
    res.usedGelatoFee = fee
    res.currentGelatoFee = gelatoFee
    return res
  }

  async function relayFeeEstimate(
    feeToken,
    chainId = Number(config.chainId),
    transferNoteLength = 0
  ) {
    const extraNoteGas = Math.ceil(
      transferNoteLength * 16 +
        (transferNoteLength / 32) * 9 +
        transferNoteLength ** 2
    )
    const estimatedFee = await gelato.getEstimatedFee(
      chainId,
      feeToken,
      2300000 + extraNoteGas,
      false
    )
    return estimatedFee
  }

  return { relay, relayFeeEstimate }
}
