const ethers = require('ethers')
// ethers@v6 fails resolving ens names
const { getDefaultProvider, providers } = require('ethers5') // eslint-disable-line
const { default: SafeApiKit } = require('@safe-global/api-kit')
const { default: Safe, EthersAdapter } = require('@safe-global/protocol-kit')
const {
  NATIVE_ADDRESS_PATTERN,
  SHIELDED_ADDRESS_PATTERN,
  queryFilterBatched
} = require('./utils')

module.exports = config => {
  // we lookup .eth .gno names on mainnet
  const GNS_REGISTRY = '0x4f132A7e39B1D717B39C789eB9EC1e790092042B'
  //FIXME replace eth rpc to fix name lookups
  // const ethereum = getDefaultProvider('https://rpc.eth.gateway.fm')
  const ethereum = { resolveName() {} }
  const gnosis = new providers.JsonRpcProvider('https://rpc.gnosischain.com', {
    name: 'xdai',
    chainId: 100,
    ensAddress: GNS_REGISTRY
  })
  return {
    /// Downloads the entire registry to config.peers.
    async load() {
      const registered = await this.list()
      config.peers = config.peers || []
      config.peers = config.peers.concat(registered.map(r => r.shieldedAddress))
    },
    /**
     * Lists all registry entries.
     * @returns [ { nativeAddress, shieldedAddress, name, expiry }, ... ]
     */
    async list() {
      return queryFilterBatched(
        config.startBlock,
        await config.provider.getBlock().then(b => b.number),
        config.registry,
        config.registry.filters.Registered()
      ).then(events =>
        events.map(({ args }) => ({
          nativeAddress: args.nativeAddress,
          shieldedAddress: args.shieldedAddress,
          name: args.name,
          expiry: Number(args.expiry)
        }))
      )
    },
    /**
     * Searches for a particular shielded address entry.
     * @param shieldedAddress
     * @return { nativeAddress, shieldedAddress, name, expiry } Record
     */
    async find(shieldedAddress) {
      return this.list().then(l =>
        l.find(entry => entry.shieldedAddress === shieldedAddress)
      )
    },
    /**
     * Whether given shielded address is registered.
     * @param shieldedAddress Shielded addresss
     * @return Registered bool flag
     */
    async isRegistered(shieldedAddress) {
      const entry = await this.find(shieldedAddress)
      return !!entry
    },
    /**
     * Get the registration fee for a name.
     * @param name .bay name
     * @return Registration fee for given name
     */
    async getFee(name) {
      return config.registry.getFee(Buffer.from(name, 'utf8'))
    },
    /**
     * Get the registration term.
     * @return Registration term
     */
    async getTerm() {
      return config.registry.term().then(Number)
    },
    /**
     * Looks up a shielded by a native address.
     * @param address Native address
     * @return Shielded address string
     */
    async shieldedAddressOf(address) {
      return config.registry.shieldedAddressOf(address)
    },
    /**
     * Looks up a shielded address by a Bermuda Bay name.
     * @param name .bay name
     * @return Shielded address string
     */
    async shieldedAddressOfName(name) {
      return config.registry.shieldedAddressOfNameHash(
        ethers.keccak256(Buffer.from(name.toLowerCase(), 'utf8'))
      )
    },
    /**
     * Looks up a native address by a shielded address.
     * @param shieldedAddress Shielded address
     * @returns Native address
     */
    async nativeAddressOf(shieldedAddress) {
      return this.find(shieldedAddress).then(r => r?.nativeAddress)
    },
    /**
     * Looks up a native address by a Bermuda Bay name.
     * @param name .bay name
     * @returns Native address
     */
    async nativeAddressofName(name) {
      const shieldedAddress = await this.shieldedAddressOfName(name)
      return this.nativeAddressOf(shieldedAddress)
    },
    /**
     * Looks up a name by a shielded address.
     * @param shieldedAddress Shielded address
     * @returns .bay name
     */
    async nameOfShieldedAddress(shieldedAddress) {
      return config.registry
        .nameOfShieldedAddress(shieldedAddress)
        .then(hexString =>
          Buffer.from(hexString.replace('0x', ''), 'hex').toString('utf8')
        )
    },
    /**
     * Looks up a name by a native address.
     * @param nativeAddress Gnosis address
     * @returns .bay name
     */
    async nameOfNativeAddress(nativeAddress) {
      return config.registry
        .nameOfNativeAddress(nativeAddress)
        .then(hexString =>
          Buffer.from(hexString.replace('0x', ''), 'hex').toString('utf8')
        )
    },
    /**
     * Looks up the expiry of a name.
     * @param name .bay name
     * @return Expiry timestamp (in seconds)
     */
    async expiryOf(name) {
      return config.registry.expiryOf(Buffer.from(name, 'utf8')).then(Number)
    },
    /**
     * Registers a shielded account including a name.
     * @param signer Ethers signer
     * @param shieldedAddress Shielded address
     * @param name .bay name
     * @param safeAddress Safe address (optional)
     */
    async register(signer, shieldedAddress, name = '', safeAddress) {
      if (safeAddress) {
        const _safeAddress = ethers.getAddress(safeAddress)
        const ethAdapter = new EthersAdapter({
          ethers,
          signerOrProvider: signer
        })
        const safeSigner = await Safe.create({
          ethAdapter,
          safeAddress: _safeAddress
        })
        const registryAddress = await config.registry.getAddress()
        const rawData = config.registry.interface.encodeFunctionData(
          'register',
          [
            Buffer.from(shieldedAddress.replace('0x', ''), 'hex'),
            Buffer.from(name, 'utf8')
          ]
        )
        const safeTxData = {
          to: ethers.getAddress(registryAddress),
          data: rawData,
          value: '0'
        }
        // const safeTx = await safe.createTransaction({ safeTransactionData })
        // const signedSafeTx = await safe.signTransaction(
        //   safeTx,
        //   'eth_signTypedData_v4'
        // )
        // const result = await safe.executeTransaction(signedSafeTx)
        // return result
        const safeTx = await safeSigner.createTransaction({
          transactions: [safeTxData]
        })
        const chainId = await signer.provider
          .getNetwork()
          .then(({ chainId }) => chainId)
        const apiKit = new SafeApiKit({ chainId })
        // Deterministic hash based on tx params
        const safeTxHash = await safeSigner.getTransactionHash(safeTx)
        // Sign Safe tx thereby adding first confirmation
        const senderSignature = await safeSigner.signHash(safeTxHash)
        await apiKit.proposeTransaction({
          safeAddress: _safeAddress,
          safeTransactionData: safeTx.data,
          safeTxHash,
          senderAddress: ethers.getAddress(signer.address),
          senderSignature: senderSignature.data
        })
        return { safeTxHash }
      } else {
        return config.registry
          .connect(signer)
          .register(
            Buffer.from(shieldedAddress.replace('0x', ''), 'hex'),
            Buffer.from(name, 'utf8'),
            { gasLimit: 250000 }
          )
          .then(response => {
            return config.provider.waitForTransaction(response.hash)
          })
          .then(receipt => {
            if (receipt.status !== 1) {
              throw Error('registration failed')
            }
            return receipt
          })
      }
    },
    /**
     * Renews an effective registration for another term, i.e. the
     * term following the current registration's expiry. Does only allow
     * renewals one term in advance.
     * @param signer Ethers signer
     * @param name .bay name
     */
    async renew(signer, name) {
      return config.registry
        .connect(signer)
        .renew(
          Buffer.from(
            ethers.keccak256(name.toLowerCase()).replace('0x', ''),
            'hex'
          ),
          { gasLimit: 250000 }
        )
        .then(response => {
          return config.provider.waitForTransaction(response.hash)
        })
        .then(receipt => {
          if (receipt.status !== 1) {
            throw Error('renewal failed')
          }
          return receipt
        })
    },
    /**
     * Resolves given input to a shielded address.
     *
     * @param {*} x Arbitrary user input
     * @returns Shielded address or undefined
     */
    async resolveShieldedAddress(x) {
      if (SHIELDED_ADDRESS_PATTERN.test(x)) {
        return x
      } else if (x.endsWith('.bay')) {
        return this.shieldedAddressOfName(x).then(r =>
          r.length === 130 ? r : undefined
        )
      } else if (NATIVE_ADDRESS_PATTERN.test(x)) {
        return this.shieldedAddressOf(x).then(r =>
          r.length === 130 ? r : undefined
        )
      } else if (x.endsWith('.eth')) {
        const nativeAddress = await ethereum.resolveName(x)
        return this.shieldedAddressOf(nativeAddress).then(r =>
          r.length === 130 ? r : undefined
        )
      } else {
        // assume it is a cirlces ubi name
        const safeAddress = await fetch(
          `https://api.circles.garden/api/users/${x}`
        )
          .then(r => (r.status !== 200 ? undefined : r.json()))
          .then(usr => usr?.data?.safeAddress)
        if (!safeAddress) {
          return undefined
        } else {
          return this.shieldedAddressOf(safeAddress).then(r =>
            r.length === 130 ? r : undefined
          )
        }
      }
    },
    /**
     * Resolves given input to a native address.
     *
     * @param {*} x Arbitrary user input
     * @returns Native address or undefined
     */
    async resolveNativeAddress(x) {
      if (NATIVE_ADDRESS_PATTERN.test(x)) {
        return x
      } else if (x.endsWith('.eth')) {
        return ethereum.resolveName(x)
      } else if (x.endsWith('.gno')) {
        return gnosis.resolveName(x)
      } else if (SHIELDED_ADDRESS_PATTERN.test(x)) {
        return this.nativeAddressOf(x).then(r =>
          r.length === 42 ? r : undefined
        )
      } else if (x.endsWith('.bay')) {
        return this.nativeAddressOfName(x)
      } else {
        // assume it is a cirlces ubi name
        return fetch(`https://api.circles.garden/api/users/${x}`)
          .then(r => (r.status !== 200 ? undefined : r.json()))
          .then(usr => usr?.data?.safeAddress)
      }
    },
    /**
     * Looks up an ENS name given a native address.
     *
     * @param {string} nativeAddress Native address
     * @returns {string} ENS name or null
     */
    async lookupEnsName(/*nativeAddress*/) {
      //FIXME replace eth rpc
      // return ethereum.lookupAddress(nativeAddress)
      return null
    },
    /**
     * Looks up a GNS/Genome name given a native address.
     *
     * @param {string} nativeAddress Native address
     * @returns {string} GNS/Genome name or null
     */
    async lookupGnoName(nativeAddress) {
      return gnosis.lookupAddress(nativeAddress)
    }
  }
}
