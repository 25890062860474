// const { Contract } = require('ethers')
const MerkleTree = require('fixed-merkle-tree')
const { BigNumber } = require('@ethersproject/bignumber')
const { prove2, prove16 } = require('./groth16')
const {
  FIELD_SIZE,
  bigNumToHex,
  getExtDataHash,
  shuffle,
  progress,
  queryFilterBatched,
  sumAmounts
} = require('./utils')

module.exports = (config, poseidonHash, poseidonHash2, poseidonHash5) => {
  const Utxo = require('./utxo')(poseidonHash)
  // const registry = require('./registry')(config)
  const safeStx = require('./safe')(
    config,
    poseidonHash,
    poseidonHash2,
    poseidonHash5
  )

  async function buildMerkleTree({ pool = config.pool }) {
    progress('Reconstructing Merkle tree')
    const filter = pool.filters.NewCommitment()
    const events = await queryFilterBatched(
      config.startBlock,
      await config.provider.getBlock().then(b => b.number),
      pool,
      filter
    )

    const leaves = events
      .sort((a, b) => Number(a.args.index) - Number(b.args.index))
      .map(e => bigNumToHex(e.args.commitment))

    return new MerkleTree(config.merkleTreeHeight, leaves, {
      hashFunction: poseidonHash2
    })
  }

  async function getProof({
    inputs,
    outputs,
    tree,
    extAmount,
    fee,
    recipient,
    relayer,
    unwrap,
    token,
    keyPair,
    noirProof,
    noirPublicInputs,
    challenge,
    blockNumber,
    blockHash
  }) {
    progress('Generating shielded transaction zk proof')
    inputs = shuffle(inputs)
    outputs = shuffle(outputs)

    let inputMerklePathIndices = []
    let inputMerklePathElements = []

    for (const input of inputs) {
      if (input.amount > 0) {
        input.index = tree.indexOf(bigNumToHex(input.getCommitment()))
        if (input.index < 0) {
          throw new Error(
            `Input commitment ${bigNumToHex(
              input.getCommitment()
            )} was not found`
          )
        }
        inputMerklePathIndices.push(input.index)
        inputMerklePathElements.push(tree.path(input.index).pathElements)
      } else {
        inputMerklePathIndices.push(0)
        inputMerklePathElements.push(new Array(tree.levels).fill(0))
      }
    }

    const encryptedOutput1 = outputs[0].encryptEphemeral
      ? outputs[0].encrypt(undefined)
      : outputs[0].encrypt(keyPair)
    const encryptedOutput2 = outputs[1].encryptEphemeral
      ? outputs[1].encrypt(undefined)
      : outputs[1].encrypt(keyPair)

    const nonce = keyPair?.nonce ?? 0
    const nonceKey = keyPair?.nonceKey ?? '0x' + '0'.repeat(64)

    const extData = {
      recipient: bigNumToHex(recipient, 20),
      extAmount: bigNumToHex(extAmount),
      relayer: bigNumToHex(relayer, 20),
      fee: bigNumToHex(fee),
      encryptedOutput1: encryptedOutput1.envelope,
      encryptedOutput2: encryptedOutput2.envelope,
      unwrap,
      token,
      nonce: bigNumToHex(nonce),
      nonceKey
    }
    const extDataHash = getExtDataHash(extData)

    let input = {
      root: tree.root(),
      publicAmount: BigNumber.from(extAmount)
        .sub(fee)
        .add(FIELD_SIZE)
        .mod(FIELD_SIZE)
        .toString(),
      extDataHash,
      challenge: BigNumber.from(challenge),

      // data for 2 transaction inputs
      inputNullifier: inputs.map(x => x.getNullifier()),
      inSafe: inputs.map(x => x.safeCommit),
      inAmount: inputs.map(x => x.amount),
      inPrivateKey: inputs.map(x => x.keypair.privkey),
      inBlinding: inputs.map(x => x.blinding),
      inToken: inputs.map(x => x.tokenCommit),
      inNote: inputs.map(x => x.noteCommit),
      inPathIndices: inputMerklePathIndices,
      inPathElements: inputMerklePathElements,

      // data for 2 transaction outputs
      outputCommitment: outputs.map(x => x.getCommitment()),
      outSafe: outputs.map(x => x.safeCommit),
      outAmount: outputs.map(x => x.amount),
      outPubkey: outputs.map(x => x.keypair.pubkey),
      outBlinding: outputs.map(x => x.blinding),
      outToken: outputs.map(x => x.tokenCommit),
      outNote: outputs.map(x => x.noteCommit)
    }
    // console.log("✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞ prove input", JSON.stringify(input))
    let proof
    if (inputs.length === 2) {
      proof = await prove2(input, config.zkAssetsBaseUrl)
    } else if (inputs.length === 16) {
      proof = await prove16(input, config.zkAssetsBaseUrl)
    }

    const args = {
      pA: proof.pi_a.slice(0, 2),
      pB: [
        [proof.pi_b[0][1], proof.pi_b[0][0]],
        [proof.pi_b[1][1], proof.pi_b[1][0]]
      ],
      pC: proof.pi_c.slice(0, 2),
      root: bigNumToHex(input.root),
      inputNullifiers: inputs.map(x => bigNumToHex(x.getNullifier())),
      outputCommitments: outputs.map(x => bigNumToHex(x.getCommitment())),
      publicAmount: bigNumToHex(input.publicAmount),
      extDataHash: bigNumToHex(extDataHash),
      noirProof,
      noirPublicInputs,
      challenge,
      blockNumber: bigNumToHex(blockNumber),
      blockHash: blockHash
    }
    // console.log("✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞ args, extData", JSON.stringify({args, extData}))
    return {
      extData,
      args,
      // same as encryptedOutput2.partialViewingKey
      partialViewingKey: encryptedOutput1.partialViewingKey
    }
  }

  async function prepareTransact({
    pool = config.pool,
    inputs = [],
    outputs = [],
    fee = 0,
    recipient = 0,
    relayer = 0,
    unwrap = false,
    token
  }) {
    console.log(
      '✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞✞ prepareTransact raw output safes',
      outputs.map(o => o.safe)
    )
    progress('Preparing shielded transaction')
    if (inputs.length > 16 || outputs.length > 2) {
      throw new Error('Incorrect inputs/outputs count')
    }
    // while (inputs.length !== 2 && inputs.length < 16) {
    //   inputs.push(
    //     new Utxo({
    //       amount: 0,
    //       token,
    //       safe: inputs[0]?.safe
    //     })
    //   )
    // }
    // while (outputs.length < 2) {
    //   outputs.push(new Utxo({ amount: 0, token }))
    // }

    const nonZeroUtxo = inputs.find(
      input => !input.amount.eq(BigNumber.from(0))
    )
    const keyPair = nonZeroUtxo?.keypair

    // if any input is owned by a safe calc msgHash
    let safe
    let msgHash
    for (const input of inputs) {
      const _safe = await safeStx.shieldedToSafe(input.keypair.address())
      if (_safe) {
        const nullifiers = inputs.map(x => x.getNullifier())
        const amounts = inputs.map(x => x.amount)
        console.log('>>>>> safe stx hash calc input nullifiers', nullifiers)
        const value = sumAmounts(inputs)
        safe = _safe
        console.log('prepareTransact safeStx.msgHash inputs', {
          token,
          safe,
          value,
          nullifiers,
          amounts
        })
        msgHash = safeStx.msgHash({ token, safe, value, nullifiers, amounts })
        break
      }
    }

    // if input utxos are owned by a safe we fetch its zk storage proof
    let noirResults
    console.log('safe, msgHash', safe, msgHash)
    if (safe && msgHash) {
      progress('Generating Safe multisig zk proof')
      console.log('>>> await safeStx.fetchNoirProof()')
      noirResults = await safeStx.fetchNoirProof(safe, msgHash)
    }
    // console.log('>>> noirResults', noirResults)
    // console.log(">>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<")
    // console.log("output safes")
    // console.log(outputs.map(o => "0x"+o.safe.toString("hex")))
    // console.log(">>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<")
    // set safe marker on output utxos
    for (const o of outputs) {
      const _safe = await safeStx.shieldedToSafe(o.keypair.address())
      if (_safe) {
        o.safe = Buffer.from(_safe.replace('0x', ''), 'hex')
        o.safeCommit = BigNumber.from(_safe)
      }
    }
    // console.log(">>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<")
    // console.log("input safes")
    // console.log(inputs.map(u => "0x"+u.safe.toString("hex")))
    // console.log(">>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<")
    // console.log(">>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<")
    // console.log("output safes")
    // console.log(outputs.map(o => "0x"+o.safe.toString("hex")))
    // console.log(">>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<")
    while (inputs.length !== 2 && inputs.length < 16) {
      inputs.push(
        new Utxo({
          amount: 0,
          token,
          safe: inputs[0]?.safe
        })
      )
    }
    while (outputs.length < 2) {
      outputs.push(new Utxo({ amount: 0, token, safe: outputs[0]?.safe }))
    }

    let extAmount = BigNumber.from(fee)
      .add(outputs.reduce((sum, x) => sum.add(x.amount), BigNumber.from(0)))
      .sub(inputs.reduce((sum, x) => sum.add(x.amount), BigNumber.from(0)))
    // console.log(">>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<")
    // console.log(" noirResults?.proof",  noirResults?.proof)
    // console.log("noirResults?.public_inputs", noirResults?.public_inputs)
    // console.log(">>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<<")
    const { args, extData, partialViewingKey } = await getProof({
      inputs,
      outputs,
      tree: await buildMerkleTree({ pool }),
      extAmount,
      fee,
      recipient,
      relayer,
      unwrap,
      token,
      keyPair,
      noirProof: noirResults?.proof ?? '0x' + '00'.repeat(32),
      noirPublicInputs: noirResults?.public_inputs ?? [],
      challenge: noirResults?.challenge ?? '0x' + '00'.repeat(32),
      blockNumber: noirResults?.block_number ?? 0,
      blockHash: noirResults?.block_hash ?? '0x' + '00'.repeat(32)
    })

    return {
      args,
      extData,
      partialViewingKey
    }
  }

  async function transact({ pool, ...rest }) {
    const { args, extData, partialViewingKey } = await prepareTransact({
      pool,
      ...rest
    })
    progress('Dispatching transaction')
    return pool
      .transact(args, extData, { gasLimit: 2300000 }) //1400000
      .then(res => {
        return config.provider.waitForTransaction(res.hash)
      })
      .then(receipt => {
        if (receipt.status === 0) {
          let err = new Error('transact failed')
          err.receipt = receipt
          throw err
        }
        return { receipt, partialViewingKey }
      })
  }
  return {
    buildMerkleTree,
    getProof,
    prepareTransact,
    transact
  }
}
