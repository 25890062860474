import { default as ReactModal } from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import { Text, Flex, Box, Image } from 'rebass'
import { Label, Checkbox } from '@rebass/forms'
import Button from './button'
import { register, dump, useMyMetaMask } from './redux'
import { copyToClipboard, shorten } from './util'

export default function RegisterModal() {
  const dispatch = useDispatch()
  const { account, chainId } = useMyMetaMask()
  const {
    shieldedAddress,
    isRegistered,
    showRegisterModal,
    safes = [],
    safeAddress,
    ensName
  } = useSelector(state => state)
  return (
    <ReactModal
      isOpen={
        typeof showRegisterModal === 'boolean'
          ? showRegisterModal
          : isRegistered === false
      }
      contentLabel="register modal"
      appElement={document.getElementById('root')}
      className="responsive-modal"
    >
      <Flex
        style={{
          justifyContent: 'center',
          marginTop: window.innerHeight < 500 ? '0' : '20vh'
        }}
      >
        <Box
          sx={{
            background: '#fff',
            border: '0.1875em solid #000',
            boxShadow: '0 0.625em',
            padding: '0.625em',
            wordWrap: 'break-word',
            textAlign: 'center',
            fontSize: ['1em', '1.25em']
          }}
        >
          <Text sx={{ fontWeight: 'bold', marginBottom: '0.625em' }}>
            ®️ Register
          </Text>
          <Text sx={{ marginBottom: '0.625em' }}>
            Publish your native and shielded address for peer discovery.
          </Text>
          {/* <Text style={{ marginBottom: '0.625em' }}>
            {' '}
            {safes.length ? (
              <Text>
                You can either register your connected EOA{' '}
                <span
                  style={{ cursor: 'grab' }}
                  onClick={copyToClipboard.bind(null, account)}
                  title="Connected account"
                >
                  <pre style={{ display: 'inline' }}>{shorten(account)}</pre>
                </span>{' '}
                or any of your Safes:
              </Text>
            ) : null}
          </Text> */}
          {/* {safes.length ? (
            <Box sx={{ marginBottom: '0.625em' }}>
              <Text></Text>
              {safes.map(({ safeAvatar, safeAddress: _safeAddress }) => {
                return (
                  <Label
                    key={_safeAddress}
                    sx={{
                      cursor: 'pointer',
                      justifyContent: 'center',
                      padding: [0, '0 15vw', '0 25vw']
                    }}
                  >
                    <Box sx={{ width: '100%', display: 'inline-flex' }}>
                      <Checkbox
                        checked={safeAddress === _safeAddress}
                        sx={{ color: '#000' }}
                        onChange={e =>
                          dispatch(
                            dump({
                              safeAddress: e.target.checked
                                ? _safeAddress
                                : null
                            })
                          )
                        }
                      />
                      <Box>
                        <Text
                          sx={{
                            minWidth: ['3.75em', '5em'],
                            display: 'inline-block'
                          }}
                        >
                          <pre style={{ display: 'inline' }}>
                            {shorten(_safeAddress)}
                          </pre>
                        </Text>
                      </Box>
                    </Box>
                  </Label>
                )
              })}
            </Box>
          ) : null} */}
          <Box sx={{ marginBottom: '1.25em' }}>
            <Text
              style={{ cursor: 'grab' }}
              onClick={copyToClipboard.bind(null, safeAddress ?? account)}
              title="Selected account"
            >
              {safeAddress ? 'Safe' : 'EOA'}{' '}
              <pre style={{ display: 'inline' }}>
                {shorten(safeAddress ?? ensName ?? account)}
              </pre>
            </Text>
            <Text
              style={{ cursor: 'grab' }}
              onClick={copyToClipboard.bind(null, shieldedAddress)}
              title="Shielded address"
            >
              Shielded address:{' '}
              <pre style={{ display: 'inline' }}>
                {shorten(shieldedAddress)}
              </pre>
            </Text>
          </Box>
          <Button
            disabled={!shieldedAddress}
            aria-label="Register"
            style={{
              margin: '0 0 0 0',
              cursor: shieldedAddress ? 'pointer' : 'none'
            }}
            onClick={() => dispatch(register(chainId))}
          >
            Register
          </Button>
        </Box>
      </Flex>
    </ReactModal>
  )
}
