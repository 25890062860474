import { useEffect } from 'react'
import { Flex, Box, Text, Image } from 'rebass'
import { Input } from '@rebass/forms'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRegistry, dump, doubleDumpFrens } from './redux'
import { copyToClipboard, dedupRegistryContacts } from './util'

export default function Community() {
  const dispatch = useDispatch()
  const {
    shieldedAddress,
    searchFilter,
    registryContacts = [],
    frens = []
  } = useSelector(state => state)
  useEffect(() => {
    dispatch(fetchRegistry())
    return async () => {}
  }, [])
  return (
    <Box
      sx={{
        maxWidth: '100%',
        border: '0.1875em solid #000',
        boxShadow: '0 0.625em',
        padding: '0.625em'
      }}
    >
      <Flex
        sx={{
          fontSize: [24, 26, 28],
          fontWeight: 'bold',
          marginTop: 0,
          flexWrap: 'wrap',
          justifyContent: 'space-between'
        }}
      >
        <span>Community</span>
      </Flex>
      <Box sx={{ margin: '0.625em 0 0.625em 0', fontSize: [16, 18, 20] }}>
        <Text
          sx={{
            textAlign: 'center',
            marginBottom: '0.625em'
          }}
        >
          Search the community and save contacts to your favorites.
        </Text>
        <Input
          type="text"
          id="search-input"
          placeholder="Native address, shielded address, or .bay name"
          onChange={e => dispatch(dump({ searchFilter: e.target.value }))}
          value={searchFilter ?? ''}
          bg="#fff"
          sx={{
            textAlign: 'center',
            marginBottom: '0.625em'
          }}
        />
        {dedupRegistryContacts(registryContacts)
          .filter((r, _i) => {
            if (searchFilter) {
              return (
                r.shieldedAddress.startsWith(searchFilter) ||
                r.name.startsWith(searchFilter) ||
                r.nativeAddress.startsWith(searchFilter)
              )
            } else {
              return true // use _i 2 slice
            }
          })
          .map(r => {
            const isFren = frens.some(
              f => f.shieldedAddress === r.shieldedAddress
            )
            const isMe = r.shieldedAddress === shieldedAddress
            return (
              <Box
                sx={{
                  display: 'inline-flex',
                  marginBottom: '0.625em',
                  padding: '0.3125em',
                  border: '0.0625em solid #000',
                  boxShadow: '0 0.125em',
                  width: '100%',
                  fontSize: '14px'
                }}
                key={r.shieldedAddress}
              >
                <Image
                  src={`https://robohash.org/${r.shieldedAddress}.png`}
                  sx={{
                    width: '20%',
                    minWidth: '4em',
                    borderRadius: 10,
                    verticalAlign: 'top',
                    margin: '0'
                  }}
                ></Image>
                <Box sx={{ position: 'relative' }}>
                  <Text
                    style={{
                      cursor: 'grab',
                      height: 'min-content',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden'
                    }}
                    onClick={copyToClipboard.bind(null, r.nativeAddress)}
                    title={r.nativeAddress}
                  >
                    {' '}
                    <Text
                      style={{
                        cursor: r.name ? 'grab' : 'initial',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                      onClick={copyToClipboard.bind(null, r.name)}
                      title={r.name}
                    >
                      {' '}
                      <code>.bay</code> name: {r.name ? r.name : 'N/A'}
                    </Text>
                    Native address: {r.gno || r.ens || r.nativeAddress}
                  </Text>
                  <Text
                    style={{
                      cursor: 'grab',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      marginRight: '0.3125em'
                    }}
                    onClick={copyToClipboard.bind(null, r.shieldedAddress)}
                    title={r.shieldedAddress}
                  >
                    {' '}
                    Shielded address: {r.shieldedAddress}
                  </Text>
                  <span
                    style={{
                      position: 'absolute',
                      top: '0',
                      right: '0.3125em',
                      fontWeight: isFren || isMe ? 'normal' : 'bold',
                      cursor: 'pointer'
                    }}
                    title={isFren ? 'Drop from favorites' : 'Save to favorites'}
                    onClick={() => {
                      let _frens
                      if (isFren) {
                        _frens = frens.filter(
                          f => f.shieldedAddress !== r.shieldedAddress
                        )
                      } else {
                        _frens = frens.concat([r])
                      }
                      dispatch(doubleDumpFrens(_frens))
                    }}
                  >
                    {isMe ? '' : isFren ? '⭐' : '★'}
                  </span>
                </Box>
              </Box>
            )
          })}
      </Box>
    </Box>
  )
}
